@import "../../../app/App/css/variables";

$CN: page-header;

.#{$CN} {
	padding: 16px 12px 0 16px;
}
@media (min-width: 500px) {
	.#{$CN} {
		padding: 16px 24px 0 24px;
	}
}

.#{$CN}__main {
	flex-grow: 1;
	display: flex;
	gap: 16px;
	margin: 12px 0 0 0;
}

.#{$CN}__content {
	flex-grow: 1;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 10px;
}

.#{$CN}__header {
	font-size: 24px;
	line-height: 32px;
	font-weight: 600;
	color: $colorTextHeading;
	flex-grow: 1;
	margin: 0;
}

.#{$CN}__description {
	font-size: 14px;
	line-height: 22px;
	font-weight: 500;
	color: $colorTextDescription;
	margin: 8px 0 0 0;
}

.#{$CN}__buttons {
	display: flex;
	gap: 10px;
	flex-wrap: wrap;
}

.#{$CN}__meta {
	margin: 12px 0 0 0;
}

.#{$CN}__tabs {
	.ant-tabs-nav {
		margin: 0;
	}
}
