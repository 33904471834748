@import "../../../../../../app/App/css/variables";

$CN: p-tariff-card-description;

.#{$CN} {
	font-size: 20px;
	line-height: 28px;
	font-weight: 500;
	color: #2C2A35;
	margin: 24px 0 0 0;
}
