.container {
    display: grid;
    gap: 32px;
}

.content {
    background-color: white;
    padding: 24px;
}

.navContainer {
	display: flex;
	justify-content: space-between;
	gap: 20px;
	margin: 0 0 3rem 0;
}