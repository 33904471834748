$CN: mobile-menu;

.#{$CN} {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	width: 100%;
	height: calc(100vh - 64px);
	background-color: rgba(0, 0, 0, 0.1);
}

.#{$CN}__inner {
	position: relative;
	height: 100%;
	max-width: 280px;
	background-color: white;
}
