@import "../../../app/App/css/variables";

$CN: gray-block;

.#{$CN} {
	background-color: $colorBgLayout;
	border-radius: 6px;

	&--padding-small {
		padding: 16px;
	}

	&--padding-middle {
		padding: 24px;
	}
}
