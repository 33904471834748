$CN: page-skeleton;

.#{$CN} {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
	width: 100%;
    justify-content: space-between;
}

.#{$CN}__header {

}

.#{$CN}__main {
	position: relative;
    flex-grow: 1;
    display: flex;

}

.#{$CN}__footer {
	padding-top: 100px;
    display: flex;
}
