$CN: 'exercise-audio-text-answer';

.#{$CN} {

}

.#{$CN}__bottom {
	display: flex;
	justify-content: right;
	margin-top: 15px;
}
