@import "../../../app/App/css/variables.scss";

$CN: livelesson-card-admin;

.#{$CN} {
	padding: 0;
	border-radius: 8px;
}

.#{$CN}__content {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 16px;
}

.#{$CN}__header {
	@include Heading__Heading-5;
	color: $colorTextHeading;
	margin: 8px 0;
	height: 48px;
}

.#{$CN}__curator-wrapper {
	display: flex;
	flex-direction: row;
	gap: 4px;
}

.#{$CN}__curator{
	@include SM__SM-Normal;
	color: $colorTextSecondary;
}

.#{$CN}__time-wrapper {
	display: flex;
	flex-direction: row;
	gap: 16px;
	color: $colorTextTertiary;
}

.#{$CN}__time-item {
	@include Base__Base-Strong;
	color: $colorPrimaryBase;
	margin: 0 0 0 4px;
}

.#{$CN}__students-wrapper {
	display: flex;
	flex-direction: row;
	gap: 4px;
	margin: 16px 0;
	height: 20px;
}

.#{$CN}__students-avatars {
	display: flex;
	flex-direction: row;
	flex-flow: wrap;
	margin: 0px;
	padding: 0px;
	:nth-child(n + 2) {
		margin-left: -0.75rem;
	}
}

.#{$CN}__students {
	@include SM__SM-Normal;
	color: $colorTextSecondary;
	margin: 0;
}

.#{$CN}__buttons-wrapper{
	display: flex;
	flex-direction: row;
	gap: 8px;
}

.#{$CN}__link-label{
	@include Base__Base-Normal;
	padding-bottom: 8px;
}

.#{$CN}__lesson-link{
	margin-top: 16px;
}