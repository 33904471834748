@import "../../../../../app/App/css/variables";

$CN: payment-conditions;

.#{$CN} {
	display: flex;
	gap: 8px;
	background-color: $colorBgLayout;
	padding: 8px;
	border-radius: 4px;

	&--horizontal {
		flex-direction: row;

		& > * {
			width: 50%;
		}
	}

	&--vertical {
		flex-direction: column;
	}
}

.#{$CN}__part {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

$CN-COND: payment-condition;

.#{$CN-COND}__header {
	@include SM__SM-Normal;
	color: $colorTextSecondary;
	margin: 0;
}

.#{$CN-COND}__text {
	@include SM__SM-Normal;
	color: $colorText;
	margin: 0;
}

.#{$CN-COND}__categories {
	display: flex;
	flex-direction: column;
	gap: 5px;
	margin: 2px 0 0 0;
}

.#{$CN-COND}__category {
	display: flex;
	align-items: center;
	gap: 8px;
	margin: 0;
}
