@import "../../../../../../../app/App/css/variables";
@import "../../../../../common/landing-common";

$CN: no-group-tariff-card;

.#{$CN} {
	width: 100%;
	max-width: 400px;
	padding: 32px;
	border: 1px solid #D5D7DC;
	border-radius: 20px;
}

.#{$CN}__header {
	font-size: 30px;
	font-weight: 700;
	line-height: 32px;
	margin: 0;

	@include allThemeGradientClassesForText();
}

.#{$CN}__description {
	font-size: 20px;
	line-height: 28px;
	font-weight: 500;
	color: #2C2A35;
	margin: 24px 0 0 0;
}

.#{$CN}__theses {
	display: grid;
	gap: 12px;
	font-size: 18px;
	line-height: 26px;
	font-weight: 500;
	color: #5E5A72;
	margin: 24px 0 0 0;
}

.#{$CN}__thesis {

}

.#{$CN}__thesis-text {
	position: relative;
	padding: 0 0 0 20px;
	margin: 0;

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 0;
		width: 10px;
		height: 10px;
		transform: translateY(-3px) rotate(45deg);
	}

	@include allThemeColorClassesForBefore();
}


.#{$CN}__price {
	display: flex;
	gap: 22px;
	font-size: 36px;
	line-height: 44px;
	font-weight: 700;
	margin: 24px 0 0 0;
}

.#{$CN}__price-part {
	display: flex;
}

.#{$CN}__price-price {
	padding: 0 4px 0 0;
	margin: 0;

	@include allThemeGradientClassesForText();

	&--old {
		color: rgba(94, 90, 114, .5);
		text-decoration: line-through;
	}
}

.#{$CN}__price-currency {
	margin: 0;

	@include allThemeGradientClassesForText();

	&--old {
		color: rgba(94, 90, 114, .5);
		text-decoration: line-through;
	}
}

.#{$CN}__buy-btn {
	width: 100%;
	font-size: 20px;
	line-height: 28px;
	font-weight: 700;
	text-align: center;
	text-transform: uppercase;
	color: white;
	cursor: pointer;
	padding: 12px 16px;
	margin: 24px 0 0 0;
	border-radius: 16px;
	border: none;

	@include allThemeGradientClasses();
}