@import "../../../../../../app/App/css/variables";
@import "../../../../common/landing-common";

$CN: tariff-card;

.#{$CN} {
	padding: 32px;
	border-radius: 20px;
	border: 1px solid #D5D7DC;

	@include allThemeColorClasses();
}
