$CN: 'exercise-result';

.#{$CN} {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
  padding: 20px 0;
  margin: 0 auto;
}
.#{$CN}__ring-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
}
.#{$CN}__ring {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
}
.#{$CN}__progress, .#{$CN}__percents-text, .#{$CN}__percents-sign {
  text-align: center;
  margin: 0;
}
.#{$CN}__progress {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}
.#{$CN}__percents-text {
  font-size: 42px;
  line-height: 42px;
  font-weight: 700;
  color: #64B625;
}
.#{$CN}__percents-sign {
  font-size: 14px;
  line-height: 16px;
  font-weight: 800;
  color: #64B625;
}
.#{$CN}__time {
  text-align: center;
}