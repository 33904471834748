.wrapper {
    background-color: white;
    padding: 16px 24px;
    border-radius: 8px;
    box-shadow: 0 0 1px 1px rgba(34, 52, 71, 0.05), 0 1px 6px -1px rgba(34, 52, 71, 0.03), 0 2px 4px rgba(34, 52, 71, 0.03);
}

.text {
    color: rgba(5, 25, 46, 0.65);
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    margin: 0;
}
