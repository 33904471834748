@import "../../../../../../../app/App/css/variables";
@import "../../../../../common/landing-common";

$CN: landing-prog-bullets-section;

.#{$CN} {
	background: white;
	padding: 40px 30px;
	border-radius: 20px;
}
@include media-monitor() {
	.#{$CN} {}
}

.#{$CN}__header {
	font-size: 36px;
	font-weight: 700;
	line-height: 44px;
	white-space: pre-wrap;

	@include allThemeGradientClassesForText();
}
