$CN: uform-old;

.#{$CN} {
	width: 100%;
	border-radius: 4px;
	.ant-input-number {
		width: 100%;
	}
	&--white-bg {
		background-color: white;
	}
	&--gray-bg {
		background-color: #fafafa;
	}
	&--padding {
		padding: 24px;
	}
}

.#{$CN}__subheader {
	font-size: 22px;
	line-height: 28px;
	font-weight: 600;
	margin: 0;
}
.#{$CN}__subheader--top-offset {
	margin-top: 20px;
}

.#{$CN}__row {
	.ant-form-item {
		margin-bottom: 0;
	}
}
.#{$CN}__row--top-offset {
	margin-top: 15px;
}
.#{$CN}__row--2-cell {
	display: grid;
	gap: 24px;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.#{$CN}__bottom {
	display: flex;
	justify-content: flex-end;
	gap: 12px;
	margin: 24px 0 0 0;
}
