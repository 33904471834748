$CN: 'exercise-audio-audio-answer';

.#{$CN} {
}

.#{$CN}__help-note {
	background-color: #fff1cc;
	padding: 22px 22px 22px 22px;
	border-radius: 5px;
}

.#{$CN}__draft-header {
	font-size: 18px;
	line-height: 24px;
	font-weight: 600;
	margin: 20px 0 10px 0;
}

.#{$CN}__players {
	display: grid;
	gap: 10px;
}