@import "../../../../../../../app/App/css/variables";
@import "../../common/common";

$CN: additional-price;

.#{$CN} {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap: 0.5em;

	font-size: 16px;
	line-height: 15px;
	font-weight: 500;
}

.#{$CN}__text {
	margin: 0;
}

.#{$CN}__prices {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 0.6em;
}

.#{$CN}__price-wrapper {
	display: flex;

	&--old {
		position: relative;
		color: $colorTextTertiary;

		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: $colorTextTertiary;
		}
	}
}

.#{$CN}__price {
	@include tariff-ghost-text;
	padding: 0 .2em 0 0;
	margin: 0;

	&--empty {
		&::after {
			content: '10';
		}
	}
}

.#{$CN}__buy-button {
	@include tariff-ghost-text;
	font-size: 18px;
	font-weight: 600;
	line-height: 22px;
	text-align: center;
	background-color: white;
	padding: 8px 12px;
	margin: 0;
	border: 1px solid #D5D7DC;
	border-radius: 8px;
}
