$CN: 'exercise-test-content';

.#{$CN}__task {
  margin: 20px 0 0 0;
}

/* ВОПРОС */
.#{$CN}__question {
  margin: 30px 0 0 0;
}
@media (min-width: 500px) {
	.#{$CN}__question {
		margin: 40px 0 0 0;
	}
}

/* ФАЙЛЫ */
.#{$CN}__files {
	margin: 20px 0 0 0;
}
.#{$CN}__file-image-w {
	max-width: 400px;
}

/* ОТВЕТЫ */
.#{$CN}__answers {
  margin: 30px 0 0 0;
}
@media (min-width: 500px) {
	.#{$CN}__answers {
		margin: 40px 0 0 0;
	}
}

/* ОТВЕТ */
.#{$CN}__answer {
	display: flex;
	font-size: 18px;
	line-height: 26px;
	cursor: pointer;
	padding: 9px 15px 9px 15px;
	border: 1px solid transparent;
	border-radius: 2px;
	transition: background-color .2s;

	&:not(.#{$CN}__answer--disabled):hover {
		background: #f4f4f6;
	}
}
@media (min-width: 500px) {
	.#{$CN}__answer {
		padding: 12px 20px 12px 20px;
	}
}

.#{$CN}__answer--checked {
  color: #1890FF;
  font-weight: 500;
}
.#{$CN}__answer--wrong {
	color: #FF0000;
	background-color: #FFF1F0;

	&:not(.#{$CN}__answer--disabled):hover {
		background-color: #ffeeee;
	}
}
.#{$CN}__answer--right {
	color: #64B625;
	background-color: #F6FFED;

	&:not(.#{$CN}__answer--disabled):hover {
		background-color: #f1ffe8;
	}
}
.#{$CN}__answer--show-wrong {
	border-color: #FF0000;
}
.#{$CN}__answer--show-right {
	border-color: #64B625;
}
.#{$CN}__answer--disabled {
	cursor: default;
}

/* ЗНАЧОК ОТВЕТА */
.#{$CN}__sign {
	margin: 5px 10px 0 0;
}
.#{$CN}__sign * {
  fill: #000;
  opacity: .5;
}

.#{$CN}__sign--checked * {
	fill: #1890FF;
	opacity: 1;
}

.#{$CN}__sign--wrong * {
	fill: #FF0000;
	opacity: 1;
}

.#{$CN}__sign--right * {
	fill: #05A660;
	opacity: 1;
}

/* ПОДВАЛ */
.#{$CN}__bottom {
	margin: 30px 0 0 0;
}
@media (min-width: 580px) {
	.#{$CN}__bottom {
		display: flex;
		flex-flow: row-reverse;
		justify-content: space-between;
		align-items: center;
		margin: 50px 0 0 0;
	}
}

.#{$CN}__bottom-left {
  display: inline-grid;
  grid-auto-flow: column;
  gap: 20px;
	margin: 25px 0 0 0;
}
@media (min-width: 580px) {
	.#{$CN}__bottom-left {
		margin: 0 0 0 0;
	}
}

.#{$CN}__bottom-right {

}
@media (min-width: 580px) {
	.#{$CN}__bottom-right {
		display: grid;
		grid-auto-flow: column;
	}
}

.#{$CN}__back-next-btns {
	display: flex;
	justify-content: space-between;
	gap: 10px;
	margin: 25px 0 0 0;
}
@media (min-width: 580px) {
	.#{$CN}__back-next-btns {
		margin: 0 0 0 0;
	}
}

.#{$CN}__bottom-result-btn {
	width: 100%;
}
@media (min-width: 580px) {
	.#{$CN}__bottom-result-btn {
		width: auto;
		margin-right: 20px;
	}
}
