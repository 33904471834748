@import "../../../../../../app/App/css/variables";
@import "../../../../common/landing-common";

$CN: p-tariff-card-price;

.#{$CN} {
	display: flex;
	gap: 22px;
	font-size: 36px;
	line-height: 44px;
	font-weight: 700;
	margin: 24px 0 0 0;
}

.#{$CN}__part {
	display: flex;
}

.#{$CN}__price {
	padding: 0 4px 0 0;
	margin: 0;

	&--current {
		@include allThemeGradientClassesForText();
	}

	&--old {
		color: rgba(94, 90, 114, .5);
		text-decoration: line-through;
	}
}

.#{$CN}__currency {
	margin: 0;

	&--current {
		@include allThemeGradientClassesForText();
	}

	&--old {
		color: rgba(94, 90, 114, .5);
		text-decoration: line-through;
	}
}
