$CN: 'exercise-description-img';

/* ОСНОВНЫЕ ЧАСТИ */
.#{$CN}__label {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;

  & + * {
    margin-top: 8px;
  }
}

.#{$CN}__label--top-margin {
  display: block;
  margin-top: 16px;
}

.#{$CN}__input-error {
  font-size: 14px;
  line-height: 20px;
  color: #EC5B57;
  margin: 5px 0 0 0;
}

/* БЛОК ВОПРОСОВ */
$CN-questions: 'exercise-description-img-matches';

.#{$CN-questions}__header {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  margin: 20px 0 10px 0;
}

/* БЛОК СООТВЕТСТВИЯ */
$CN-match: 'exercise-description-img-match';

.#{$CN-match} {
  display: flex;
  padding: 0 0 16px 0;
  border-bottom: 1px solid rgb(30, 30, 30);
}
.#{$CN-match} + .#{$CN-match} {
  margin-top: 20px;
}

.#{$CN-match}__content {
  flex-grow: 1;
}

.#{$CN-match}__remove-button {
  margin: 29px 0 0 16px;
}

/* БЛОК ПРОВАЛЕННОЙ ЗАГРУЗКЕ ВИДЕО */
$CN-video-error-upload: 'exercise-test-video-error-upload';

/*.#{$CN-video-error-upload} {

}*/

/*.#{$CN-video-error-upload}__text {

}*/

/* БЛОК ПРОВАЛЕННОЙ ЗАКАЧКИ КАРТИНКИ */
$CN-media-upload-error: 'exercise-test-media-upload-error';

/*.#{$CN-media-upload-error}__text {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: #87898E;
  margin: 0 0 0 0;
}*/


/* КНОПКА ДОБАВЛЕНИЯ НОВОГО ВОПРОСА */
$CN-add-media-button: 'exercise-description-img-add-question-button';

.#{$CN-add-media-button} {
  margin: 16px 0 0 0;
}
