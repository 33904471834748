@import "../../../../../../app/App/css/variables";
@import "../../../../common/landing-common";

$CN: p-tariff-card-header;

.#{$CN} {
	font-size: 30px;
	font-weight: 700;
	line-height: 32px;
	color: #2C2A35;
	margin: 0;

	&--black {
		color: #2C2A35;
	}

	@include allThemeGradientClassesForText();
}
