.NewFolder {
  width: 300px;
  text-align: center;
  background: #FFFFFF;
  color: #515365;
}

.NewFolder__Input {
  height: 30px;
  border: 1px solid var(--Neutral, #DFE4EA);
  border-radius: 4px;
}

.NewFolder__Actions {
  height: 52px;
  padding: 10px 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items:flex-end;
  gap: 6px;
  padding-bottom: 0;
}


