$CN: 'exercise-gaps-in-words';

/* ОСНОВНЫЕ ЧАСТИ */
.#{$CN}__label {
	display: block;
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
	margin-bottom: 5px;
}

.#{$CN}__label--top-margin {
	margin-top: 16px;
}

.#{$CN}__input-error {
	font-size: 14px;
	line-height: 20px;
	color: #EC5B57;
	margin: 5px 0 0 0;
}

/* БЛОКИ ТЕКСТОВ */
$CN-texts: 'exercise-gaps-in-words-text-blocks';

/*.#{$CN-texts} {}*/

/* БЛОК ТЕКСТА */
$CN-text: 'exercise-gaps-in-words-text-block';

.#{$CN-text} {
	display: flex;
}
.#{$CN-text}__left {
	flex-grow: 1;
}
.#{$CN-text}__right {
	padding: 39px 0 0 14px;
}

/* РЕДАКТОР */
$CN-editor: 'exercise-gaps-in-words-editor';

.#{$CN-editor}__wrapper {

}

.#{$CN-editor}__make-gap-btn {
	margin: 10px 0 0 0;
}

/* КНОПКА ДОБАВЛЕНИЯ НОВОГО БЛОКА ВИДЕО */
$CN-add-button: 'exercise-gaps-in-words-add-text-block';

.#{$CN-add-button} {
	padding: 10px 0 0 0;
	margin-top: 20px;
	border-top: 1px solid #D9D9D9;
}
