@import "../../../../../../app/App/css/variables";

$CN: tariff-cards;

.#{$CN} {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 24px;
}

