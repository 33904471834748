$CN: conditions-form;

.#{$CN} {
	flex-grow: 1;
}

.#{$CN}__parts {

}

.#{$CN}__left-part {
	display: none;
}

.#{$CN}__right-part {
}

@media (min-width: 700px) {
	.#{$CN}__parts {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 32px;
	}

	.#{$CN}__left-part {
		position: relative;
		display: block;

		& > * {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			overflow: auto;
		}
	}
}

@media (min-width: 800px) {
	.#{$CN}__left-part {
		max-width: 300px;
	}
}

.#{$CN}__inputs-wrapper {
	display: flex;
	gap: 16px;
}

.#{$CN}__add-num-input {
	min-width: 40px;
	max-width: 50px;
}