@import "../../../../../../../app/App/css/variables";
@import "../../../../../common/landing-common";

$CN: p-additional-price;

.#{$CN} {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap: 0.5em;
}

.#{$CN}__text {
	font-size: 12px;
	font-weight: 500;
	line-height: 14px;
	color: #2C2A35;
	margin: 0;
}

.#{$CN}__prices {
	display: flex;
	gap: 0.6em;
}

.#{$CN}__price-wrapper {
	display: flex;
	font-size: 14px;
	font-weight: 500;
	line-height: 26px;

	@include allThemeGradientClassesForText();

	&--old {
		color: rgba(0, 0, 0, .4);
	}
}

.#{$CN}__price {
	padding: 0 .2em 0 0;
	margin: 0;
}

.#{$CN}__buy-button {
	font-size: 16px;
	line-height: 24px;
	font-weight: 600;
	text-align: center;
	text-transform: uppercase;
	background-color: white;
	cursor: pointer;
	padding: 8px 12px;
	margin: 0;
	border: 1px solid #D5D7DC;
	border-radius: 8px;

	@include allThemeGradientClassesForText();
}
