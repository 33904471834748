$CN: constructor-layout;

.#{$CN} {

}

.#{$CN} + .#{$CN} {
  margin-top: 70px;
}

.#{$CN}__content {
  max-width: 1200px;
  margin-top: 70px;
}
@media (min-width: 1000px) {
	.#{$CN}__content {
		display: flex;
		flex-flow: row-reverse;
	}
}

.#{$CN}__left {
  flex-grow: 1;
}

.#{$CN}__right {
	flex-shrink: 0;
	display: flex;
	justify-content: right;
	margin: 0 0 20px 0;
}

@media (min-width: 1000px) {
	.#{$CN}__right {
		align-items: flex-start;
		padding: 0 0 0 22px;
		margin: 0;
	}
}

.#{$CN}__settings {
    display: flex;
    align-items: center;
    justify-items: end;
}

.#{$CN}__buttons {
  margin-right: 5px;
}
