@import "../../../../../../app/App/css/variables";

$CN: tariff-card-focus-wrapper;

.#{$CN} {
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 16px;
	border: 1px solid transparent;
	border-radius: 8px;
	transition: border-color .2s;
	width: 100%;

	&:hover:not(&--active) {
		border-color: $colorPrimaryBorder;
	}

	&--active {
		border-color: $colorPrimaryBase;
	}
}

@media (min-width: 500px) {
	.#{$CN} {
		width: 380px;
	}
}