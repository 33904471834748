$CN: 'exercise-video';

/* ОСНОВНЫЕ ЧАСТИ */
.#{$CN}__label {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;

  & + * {
    margin-top: 10px;
  }
}

.#{$CN}__hr {
  height: 1px;
  background-color: #D9D9D9;
  margin: 24px 0;
}

.#{$CN}__input-error {
  font-size: 14px;
  line-height: 20px;
  color: #EC5B57;
  margin: 5px 0 0 0;
}

/* БЛОК С ВИДЕО */
$CN-video: 'exercise-video-block';

.#{$CN-video} {
  display: flex;
}
.#{$CN-video}__left {
  flex-grow: 1;
}
.#{$CN-video}__right {
  padding: 32px 0 0 14px;
}
.#{$CN-video}__upload-btn {
  font-weight: 500;
  margin: 10px 0 20px -16px;
}

/* БЛОК С КНОПКОЙ ЗАКАЧКИ ВИДЕО */
$CN-uploading-btn: 'exercise-video-uploading-btn';

.#{$CN-uploading-btn}__wrapper {
  margin: 15px 0 20px 0;
}

/* БЛОК ЗАКАЧКИ ВИДЕО */
$CN-upload-uploading: 'exercise-video-uploading';

.#{$CN-upload-uploading} {
  margin: 10px 0 24px 0;
}

/* БЛОК УДАЧНОЙ ЗАКАЧКИ ВИДЕО */
$CN-upload-success: 'exercise-video-success-upload';

.#{$CN-upload-success} {
  margin: 10px 0 24px 0;
}

/* БЛОК ПРОВАЛЕННОЙ ЗАКАЧКИ ВИДЕО */
$CN-upload-error: 'exercise-video-error-upload';

.#{$CN-upload-error} {
  margin: 10px 0 24px 0;
}
.#{$CN-upload-error}__text {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: #87898E;
  margin: 0 0 10px 0;
}

/* КНОПКА ДОБАВЛЕНИЯ НОВОГО БЛОКА ВИДЕО */
$CN-add-button: 'exercise-video-add-video-block';

.#{$CN-add-button} {
  //margin: 0 0 20px 0;
}