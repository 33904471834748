@import "../../../../app/App/css/variables";
$CN: lesson-preset;

.#{$CN} {
    width: max-content;
    height: 50px;
	cursor: pointer;
	border-color: transparent;
	border-radius: 8px;
	box-shadow: 0 2px 3px rgba(34, 52, 71, 0.1);
	// transition: box-shadow .2s;
    transition: all .2s;

	&:hover {
		// box-shadow: 0 2px 3px rgba(34, 52, 71, 0.2);
        background-color: $colorPrimaryBg;
	}

    .ant-card-body {
        display: flex;
        height: 100%;
        padding: 8px 16px 8px 8px;
    }
}

.#{$CN}__icon {
    width: 30px;
    height: 30px;

    span,
    svg {
        width: 100%;
        height: 100%;
    }
}

.#{$CN}__content {
    width: auto;
    flex: 1 1 auto;
}

.#{$CN}__name {
    @include SM__SM-Strong();
    color: $colorTextHeading;
}