@import "../../app/App/css/variables";

$CN: 'meta-output-text';

.#{$CN} {
	@include SM__SM-Normal;
	color: $colorTextLabel;
	vertical-align: center;
	margin: 0;
}

.#{$CN}__text {
	&--strong {
		@include SM__SM-Strong;
		color: $colorTextHeading;
	}
}

.#{$CN}__icon {
	margin: 0 4px 0 0;
}
