@import "../../app/App/css/variables";

$CN: 'meta-output';

.#{$CN} {
	display: flex;
	flex-wrap: wrap;
	background-color: #F2F3F9;
	padding: 4px 0;
	border-radius: 6px;
}

.#{$CN}__item {
	padding: 0 16px 0 16px;
	margin: 2px 0;

	&:not(:last-child) {
		border-right: 1px solid $colorSplit;
	}
}
