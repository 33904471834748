.List {
    display: flex;
    flex-direction: column;
    max-width: 370px;
    width: 100%;
    background: var(--color-white-0);
    border: 1px solid var(--color-gray-81);
    border-radius: 8px 0 0 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: var(--color-dark-210);
    overflow-y: scroll;
    height: 100vh;

    &__Box {
        display: flex;
        align-items: center;
        column-gap: 8px;
    }
}

.Inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-gray-110);
    padding: 12px 13px;

    & :global(.ant-select) {
        width: 100%;
    }
}

.Name {
    margin-left: 8px;
}

.Filter {
    margin: 0 21px;

    & svg {
        color: var(-color-white-250);
    }
}

.Option {
    display: flex;
    column-gap: 10px;
}

.Date {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--color-gray-270);
}

.Content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.Header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 67px;
    background: var(--color-white-0);
    border: 1px solid var(--color-white-250);
    border-radius: 0;
    padding: 12px 14px;
}

.Title {
    display: flex;
    margin: auto;
}

.Avatar {
    margin-right: 12px;
}

.Area {
    background: var(--color-white-110);
    display: flex;
    flex-direction: column;
    padding: 50px;

    &__Date {
        background: var(--color-gray-110);
        mix-blend-mode: normal;
        opacity: 0.2;
        border-radius: 50px;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: -0.078px;
        color: var(--color-white-0);
        max-width: 100px;
        width: 100%;
        display: flex;
        justify-content: center;
        margin: auto;
    }
}

.Author {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: var(--color-blue-90);
    margin-bottom: 5px;
}

.Text {
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.41px;
    color: var(--color-dark-420);
}

.Time {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    text-align: right;
    letter-spacing: 0.07px;
    color: var(--color-gray-120);
}

.Block {
    display: flex;
    align-items: flex-end;
    column-gap: 8px;
    margin-bottom: 18px;

    &__Inner {
        max-width: fit-content;
        width: 100%;
        background: var(--color-white-0);
        border: 0.5px solid var(--color-gray-75);
        border-radius: 12px;
        padding: 10px;
    }
}

.Reply {
    display: flex;
    border-left: 2px solid var(--color-blue-90);
    margin-bottom: 6px;

    &__Avatar {
        margin: 0 6px 0 8px;
    }

    &__Text {
        max-width: 240px;
        width: 100%;
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 22px;
        letter-spacing: -0.41px;
        color: var(--color-dark-420);
    }
}

.Send {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 860px;
    height: 51px;
    background: var(--color-white-0);
    box-shadow: 0 -0.5px 0 var(--color-dark-351), 0 0.5px 0 var(--color-dark-351);

    &__Input {
        background: var(--color-white-55);
        border: 0.5px solid var(--color-white-201);
        border-radius: 18px;
    }

    & :global(.emoji-picker-react) {
        position: absolute;
        right: 0;
        bottom: 55px;
    }

    &__Arrow {
        margin: 0 21px;
    }
}

.PaperClip {
    margin: 0 16px;

    & svg {
        color: var(--color-gray-95);
    }
}