.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.leftText {
    font-size: 14px;
    line-height: 18px;
    color: #292935;
    margin: 0;
}

.buttons {
    display: flex;
    gap: 20px;
}