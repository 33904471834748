@import "../../../../app/App/css/variables.scss";

$CN: lessons-presets-list;

.#{$CN}__scrollbar {
    .scrollbar__content {
        min-width: 100%;
        width: max-content;
        height: max-content;
        white-space: nowrap;
        padding-bottom: 16px;
    }
}

.#{$CN}__wrapper {
    width: calc(100% - 16px);
}

.#{$CN}__all-presets-wrapper {
    position: sticky;
    top: 0;
    right: 0;
    border-left: 16px solid $colorBgLayout;
    border-bottom: 4px solid $colorBgLayout;
}