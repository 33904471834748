$CN: 'exercise-video-content';

.#{$CN}__video-block + .#{$CN}__video-block {
  margin-top: 30px;
}

.#{$CN}__video-name {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  margin: 0 0 10px 0;
}

.#{$CN}__video-name + .exercise-video__iframe {
  margin: 7px 0 0 0;
}

.#{$CN}__video-name + .exercise-video__video {
  margin: 7px 0 0 0;
}
