@import '../../../app/App/css/adaptive';
$CN: livelessons-list;

.#{$CN} {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 317px));
	grid-auto-rows: 342px;
	gap: 24px;
	@include size($breakpoint-tablet) {
		justify-content: center;
	}
}

.#{$CN}__new {
	border-radius: 8px;
}