@import "../../../../../../app/App/css/variables";
@import "../../../../common/landing-common";

$CN: landing-portrait-blocks;

.#{$CN} {
	display: grid;
	gap: 20px;
}
@include media-monitor() {
	.#{$CN} {
		grid-template-columns: 1fr 1fr;
	}
}

.#{$CN}__column {
	display: flex;
	flex-direction: column;
	gap: 20px;
}
