@import "../../../../../../app/App/css/variables";
@import "../../../../common/landing-common";

$CN: landing-portrait-block;

.#{$CN} {
	padding: 24px 20px;
	border-radius: 20px;

	&--white {
		border: 1px solid #D5D7DC;

		.#{$CN}__block-text {
			color: #5E5A72;
		}
	}

	@include allThemeGradientClasses();

	&--colorScheme_1, &--colorScheme_2, &--colorScheme_3, &--colorScheme_4 {
		color: white;
	}
}

@include media-monitor() {
	.#{$CN} {
		padding: 40px;
	}
}

.#{$CN}__content {
	display: flex;
	flex-direction: column-reverse;
	gap: 10px;
}
@include media-monitor() {
	.#{$CN}__content {
		flex-direction: row;
		align-items: flex-start;
	}
}

.#{$CN}__text-part {
	flex-grow: 1;
}

.#{$CN}__header {
	font-size: 22px;
	font-weight: 600;
	line-height: 30px;
	white-space: pre-wrap;
	margin: 0;
}

.#{$CN}__header + .#{$CN}__text {
	margin-top: 10px;
}

.#{$CN}__text {
	font-size: 18px;
	font-weight: 500;
	line-height: 26px;
	white-space: pre-wrap;
	margin: 0;
}

.#{$CN}__img-part {
	min-width: 80px;
	max-width: 80px;

	min-height: 80px;
	max-height: 80px;
}

.#{$CN}__block-img {
	object-fit: cover;
	width: 100%;
	height: 100%;
}
