@import '../../../../app/App/css/adaptive';

.AdminComments {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.CommentsLayout {
	width: 100%;
}

.Panel {
	padding: 9px 10px;
	line-height: 1.5 !important;
	@include size-tablet {
		display: none;
	}
}

.HeaderContainer {
	display: flex;
	width: 100%;
	padding: 30px 40px 0;
	margin-bottom: 60px;
	justify-content: space-between;
	align-items: baseline;
	h2 {
		font-size: 30px;
	}
}
