@import "../../../../../../../app/App/css/variables";

$CN: p-tariff-additional-prices;

.#{$CN} {
	display: grid;
	gap: 24px;
	margin: 24px 0 0 0;
}

