@import "../../app/App/css/variables";

$CN: empty-block;

.#{$CN} {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	max-width: 600px;
}

.#{$CN}__image {
	width: 200px;
}

.#{$CN}__text {
	@include Heading__Heading-5;
	text-align: center;
	margin: 0;
}

.#{$CN}__button-wrapper {
    display: flex;
    justify-content: center;
}

.#{$CN}__bottom-wrapper {

}