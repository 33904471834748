@import '../../../../app/App/css/adaptive';

.Panel {
	padding: 0;
	span {
		line-height: 1.5 !important;
	}
	@include size-tablet {
		display: none;
	}
}

.Link {
	display: grid;
	grid-template-columns: 80% 20%;
	padding-left: 24px;
	width: 100%;
	margin-bottom: 10px;
	&:last-child {
		margin-bottom: 0;
	}
	a {
		color: #979798;
	}
}

.ActiveLink {
	a,
	span {
		color: var(--color-blue-100);
		text-shadow: 0 0 #979798;
	}
}

.AllLink {
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	padding-left: 40px;
	display: block;
	margin-bottom: 10px;
	@include size-tablet {
		display: none;
	}
}
