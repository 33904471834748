@import "../../../../../../app/App/css/variables";
@import "../common/common";
@import "../../../../common/landing-common";

$CN: tariff-card-buy-btn;

.#{$CN} {
	font-size: 20px;
	line-height: 28px;
	font-weight: 700;
	text-align: center;
	text-transform: uppercase;
	padding: 12px 16px;
	margin: 0;
	border-radius: 16px;
	border: none;

	// Белая кнопка
	&--white {
		color: rgb(60, 60, 60);
		background-color: white;
	}

	// Цветная кнопка
	&--colored {
		color: white;
	}

	@include allThemeGradientClasses();
	//@include allThemeGradientClassesForText();
}
