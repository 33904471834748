@import "../../../../../app/App/css/variables";

$CN: land-const;

.#{$CN} {
	position: relative;
	width: 100%;
	min-height: 100vh;
}

.#{$CN}__sections {
	display: grid;
	gap: 48px;
	margin: 80px 0 0 0;
}
