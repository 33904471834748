$CN: pay-cards-article;

.#{$CN} {
	position: relative;
}

.#{$CN}__content {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 40px;
	padding: 0 10px;
}

@media (min-width: 400px) {
	.#{$CN}__content {
		padding: 0 40px;
	}
}