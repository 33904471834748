@import "../../app/App/css/variables";

$CN: 'no-content';

.#{$CN} {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12px;
	text-align: center;
}

.#{$CN}__header, .#{$CN}__text {
	margin: 0;
}

.#{$CN}__header {

}

.#{$CN}__text {

}

.#{$CN}__btn {

}
