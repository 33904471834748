$CN: live-lesson-creation;

.#{$CN} {

}

.#{$CN}__content {
    display: grid;
    gap: 16px;
}

.#{$CN}__tip {
    display: inline-block;
    background-color: #F3F3F6;
    padding: 20px;
    border-radius: 8px;
}
