$CN: modal-with-form;

// ===== ОБЁРТКА КАРТОЧЕК =====

.#{$CN} {
	.ant-modal-footer {
		margin: 0;
	}

	.ant-form-item {
		margin: 0 !important;
	}
}