@import '../../../../app/App/css/variables.scss';

.ExpressLesson {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 24px;
	padding: 24px;
	border-radius: 20px;
	background: var(--colorBorderBg, #fff);
	/* boxShadow */
	box-shadow: 0px 2px 4px 0px rgba(34, 52, 71, 0.03),
		0px 1px 6px -1px rgba(34, 52, 71, 0.03),
		0px 0px 1px 1px rgba(34, 52, 71, 0.05);

	&__Title {
		@include XL__XL-Strong;
		color: var(--colorTextHeading, rgba(5, 25, 46, 0.88));

		@media only screen and (min-device-width: 768px) {
			width: 80%;
		}
	}

	&__Text {
		@include Base__Base-Strong;
		color: var(--colorText, rgba(5, 25, 46, 0.88));

		@media only screen and (min-device-width: 768px) {
			width: 80%;
		}
	}

	&__SubTitle {
		@include LG__LG-Strong;
		color: var(--colorText, rgba(5, 25, 46, 0.88));

		@media only screen and (min-device-width: 768px) {
			width: 80%;
		}
	}

	&__Alert {
		@media only screen and (min-device-width: 768px) {
			width: 80%;
		}
	}

	&__List {
		li {
			@include Base__Base-Strong;
			color: var(--colorText, rgba(5, 25, 46, 0.88));
		}

		@media only screen and (min-device-width: 768px) {
			width: 80%;
		}
	}

	&__Video {
		width: 100%;
		flex: 1 1 auto;
		position: relative;
		padding-top: 56.25%;

		@media only screen and (min-device-width: 768px) {
			width: 80%;
		}
	}

	&__ReactPlayer {
		position: absolute;
		top: 0;
		left: 0;
	}
}
