@import "../../../../../../app/App/css/variables";

$CN: tariff-panel;

.#{$CN} {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: white;
	overflow: auto;
	padding: 0 20px;
}
@media (min-width: 800px) {
	.#{$CN} {
		left: auto;
		right: 0;
		width: 320px;
		border-left: 1px solid $colorBorder;
	}
}

.#{$CN}__segment {
	padding: 24px;
	border-bottom: 1px solid $colorFullContent;
}

.#{$CN}__bottom {
	display: grid;
	gap: 24px;
	padding: 24px 0 0 0;
	margin: 24px 0 0 0;
	border-top: 1px solid $colorFullContent;
}
