$CN: 'exercise-audio-content';

.#{$CN}__audios-wrapper {
  display: grid;
  gap: 20px;

  & > * {
    width: 100%;
  }
}

.#{$CN}__audios-wrapper {
  
}

.#{$CN}__audios-hr {
	height: 1px;
	background-color: #d5d5d5;
	margin: 30px 0;
}

.#{$CN}__transcript-collapse-text {
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  cursor: pointer;
  margin: 30px 0 8px 0;
}

.#{$CN}__transcript-collapse-triangle {
  transform: rotate(-90deg);
  transition: transform .1s;
  margin-left: 5px;
}
.#{$CN}__transcript-collapse-triangle--open {
  transform: rotate(0deg);
}

.#{$CN}__transcript-content {
  font-size: 14px;
  line-height: 22px;
}