@import "../../../../../app/App/css/variables";

$CN: landing;

.#{$CN} {
	min-height: 100vh;
	width: 100%;
}

.#{$CN}__sections {
	display: grid;
	gap: 60px;
	max-width: 1240px;
	padding: 0 3%;
	margin: 0 auto;
}

@media (min-width: 600px) {
	.#{$CN}__sections {
		margin: 0 auto;
	}
}