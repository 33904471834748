$CN: 'article-content-wrapper';

.#{$CN} {
	flex-grow: 1;
	display: flex;
	align-items: flex-start;
	width: 100%;
	height: 100%;
	padding: 12px 12px 50px;
}

@media (min-width: 700px) {
	.#{$CN} {
		padding: 24px;
	}
}
.#{$CN}--center {
	justify-content: center;
	align-items: center;
}

.#{$CN}__inner {
	width: 100%;
	margin: 0 auto;
}
.#{$CN}__inner--extra-small-width {
	max-width: 450px;
}
.#{$CN}__inner--small-width {
	max-width: 650px;
}
.#{$CN}__inner--middle-width {
	max-width: 1000px;
}
.#{$CN}__inner--big-width {
	max-width: 1250px;
}
.#{$CN}__inner--center {
	display: flex;
	justify-content: center;
	align-items: center;
}
