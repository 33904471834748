@import "../../../../../../app/App/css/variables";
@import "../common/common";

$PRICES: tariff-card-prices;

.#{$PRICES} {
	display: flex;
	gap: 20px;

	margin: 24px 0 0 0;
}

$PRICE: tariff-card-price;

.#{$PRICE} {
	display: flex;
	font-size: 36px;
	line-height: 44px;
	font-weight: 700;

	&--current {
		font-weight: 600;
		color: rgb(30, 30, 30);
	}

	&--old {
		position: relative;
		color: $colorTextTertiary;

		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: $colorTextTertiary;
		}
	}
}

.#{$PRICE}__price {
	@include tariff-ghost-text;
	cursor: text;
	padding: 0 0.2em 0 0;
	margin: 0;

	&--empty {
		&::after {
			content: '10';
		}
	}
}

.#{$PRICE}__currency {
	margin: 0;
}
