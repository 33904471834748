$CN: 'exercise-drop-word-content';

.#{$CN}__text-blocks {
	position: relative;
	display: grid;
	gap: 20px;
}

.#{$CN}__words {
	position: sticky;
	top: 0;
	z-index: 1;
	max-height: 30vh;
	background-color: white;
	overflow: auto;
	padding: 0 0 8px 8px;
	margin: 30px 0 30px 0;
	border: 1px dashed #EBEFF2;
	border-radius: 4px;
	transition: background-color .3s;
}

.#{$CN}__words--shadow {
	background-color: #dce0e8;
}

.#{$CN}__word {
	font-size: 15px;
	line-height: 18px;
	font-weight: 500;
	color: white;
	text-align: left;
	background-color: #00ADF9;
	cursor: grab;
	user-select: none;
	padding: 5px 10px;
	border: none;
	border-radius: 3px;
	transition: background-color .3s, color .3s;

	&--inside {
		margin: 10px 10px 0 0;
	}
	&--error {
		color: #1F1F1F;
		background-color: #FFF1F0;
		border: 1px solid #FFC0C0;
	}
	&--success {
		color: #1F1F1F;
		background-color: #F6FFED;
		border: 1px solid #8DE748;
	}
	&--ghost {
		color: rgb(140, 140, 140);
		background-color: white;
		cursor: default;
	}
}

.#{$CN}__slot {
	display: inline-block;
	min-width: 40px;
	min-height: 28px;
	background-color: white;
	vertical-align: middle;
	border: 1px dashed #d5d8dc;
	border-radius: 4px;

	&:focus {
		border-color: #1DABF2;
	}
}
.#{$CN}__slot--wide {
	min-width: 80px;
}
.#{$CN}__slot--shadow {
	background-color: #dce0e8;
	border-color: #1DABF2;
}

.#{$CN}__slot--success {
  background-color: #F6FFED;
  border-color: #8DE748;
}
.#{$CN}__slot--fail {
  background-color: #FFF1F0;
  border-color: #FFC0C0;
}

.#{$CN}__right-answer {
  display: none;
  font-weight: bold;
  color: white;
  background-color: #72CC19;
	vertical-align: middle;
  padding: 2px 3px;
  margin: 0 0 0 2px;
  border-radius: 4px;
}
.#{$CN}__right-answer--open {
  display: inline-block;
}

.#{$CN}__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0 0 0;
}

.#{$CN}__bottom-attempts {
  margin: 0;
}



