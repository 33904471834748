@import "../../app/App/css/variables";

$CN: utable;

.#{$CN}__table-wrapper {
	width: 100%;
	overflow: auto;
	background-color: white;
	border-radius: 8px;
	box-shadow: 0 0 1px 1px rgba(34, 52, 71, 0.05), 0 1px 6px -1px rgba(34, 52, 71, 0.03), 0 2px 4px rgba(34, 52, 71, 0.03);
}

.#{$CN}__table-header {
	font-size: 15px;
	line-height: 24px;
	font-weight: 600;
	color: $colorTextHeading;
	background-color: white;
	padding: 16px;
	margin: 0;
	border-bottom: 1px solid $colorSplit;
}

.#{$CN} {
	width: 100%;
	background-color: white;
}

.#{$CN}__head {

}

.#{$CN}__head-row {
	@include Base__Base-Strong;
	color: $colorTextSecondary;
	text-align: left;
}

.#{$CN}__head-cell {
	vertical-align: baseline;
	padding: 16px 16px;
}

.#{$CN}__head-cell-text {
	position: relative;
	margin: 0;
}

.#{$CN}__head-cell:not(:last-child) {
	.#{$CN}__head-cell-text:after {
		content: '';
		position: absolute;
		top: 0;
		right: -16px;
		width: 1px;
		height: 100%;
		background-color: $colorSplit;
	}
}

.#{$CN}__body {

}

.#{$CN}__body-row {
	border-top: 1px solid $colorSplit;

	&--bg-on-hover {
		background-color: rgba(255, 255, 255, 0.89);

		&:hover {
			background-color: #F8F9FC;
		}
	}
}

.#{$CN}__body-cell {
	position: relative;
	padding: 16px 16px;
}

.#{$CN}__body-cell-link {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

}

.#{$CN}__body-cell-text {
	@include Base__Base-Normal;
	margin: 0;
}

.#{$CN}__twi {
	display: flex;
	gap: 8px;
	align-items: center;
}

.#{$CN}__twi-icon {

	&--gray {
		color: $colorIcon;
	}

	&--green {
		color: $colorSuccess;
	}
}

.#{$CN}__twi-text {
	@include Base__Base-Normal;
	line-height: 20px;
	margin: 0;
}

// ===================

.#{$CN}__head-cell-search-content {
	display: flex;
	justify-content: space-between;
	gap: 12px;
}

.#{$CN}__head-cell-search-content-text {
	margin: 0;
}

.#{$CN}__head-cell-search-content-buttons {
	display: flex;
}

.#{$CN}__head-cell-search-content-button {
	color: #b0b0b0 !important;
}

.#{$CN}__head-cell-search-content-icon {
	font-size: 12px;
}