$CN: 'exercise-result';

.#{$CN} {
    display: flex;
    flex-direction: column;
    align-items: center;
    //width: 150px;
    //padding: 20px 0;
    //margin: 0 auto;
}

.#{$CN}__ring-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    margin-bottom: 20px;
}

.#{$CN}__ring {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
}

.#{$CN}__percents-text, .#{$CN}__percents-sign {
    text-align: center;
    margin: 0;
}

.#{$CN}__percents-text {
    font-size: 42px;
    line-height: 42px;
    font-weight: 700;
    color: #64B625;
}

.#{$CN}__percents-sign {
    font-size: 14px;
    line-height: 16px;
    font-weight: 800;
    color: #64B625;
}

.#{$CN}__stats {
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    text-align: center;
}

.#{$CN}__stats-paragraph {
    white-space: nowrap;
    margin: 0;
}

.#{$CN}__pass-again-btn {
    margin-top: 30px;
}
