@import "../../../app/App/css/variables";

$CN: card-sticker;

.#{$CN} {
	position: relative;

	&::before {
		content: attr(data-text);
		position: absolute;
		top: 16px;
		right: -8px;
		z-index: 1;
		@include Base__Base-Normal;
		color: white;
		padding: 1px 8px;
		border-radius: 4px 4px 0 4px;
	}

	&::after {
		content: '';
		position: absolute;
		top: 40px;
		right: -8px;
		z-index: 1;
		clip-path: polygon(0 0, 0% 100%, 100% 0);
		height: 6px;
		width: 8px;
	}

	&--color-blue {
		&::before {
			background-color: $colorPrimaryBase;
		}
		&::after {
			background-color: $colorPrimaryActive;
		}
	}

	&--color-purple {
		&::before {
			background-color: $colorPurple6;
		}
		&::after {
			background-color: $colorPurple7;
		}
	}

	&--color-orange {
		&::before {
			background-color: $colorGold6;
		}
		&::after {
			background-color: $colorOrange7;
		}
	}

	&--color-green {
		&::before {
			background-color: $colorGreen6;
		}
		&::after {
			background-color: $colorGreen7;
		}
	}
}
