$CN: visits;

.#{$CN} {
	display: grid;
	gap: 20px;
}

@media (min-width: 500px) {
	.#{$CN} {
		grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
		grid-template-rows: repeat(4, auto);
	}
}
