.container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin: 0 0 1rem 0;
}

.counter {
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 0.125px;
}