@import "../../app/App/css/variables";

$CN: create-new-card;

.#{$CN} {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: transparent;
	cursor: pointer;
	padding: 40px 24px 40px 24px;
	border: 1px dashed $colorPrimaryBase;
	border-radius: 8px;

	&:hover {
		border-color: $colorPrimaryActive;

		.#{$CN}__text-icon {
			color: $colorPrimaryActive;
		}

		.#{$CN}__text {
			color: $colorPrimaryActive;
		}
	}

	&:disabled {
		cursor: default;
		border-color: #afafaf;

		.#{$CN}__text-icon {
			color: #afafaf;
		}

		.#{$CN}__text {
			color: #afafaf;
		}
	}
}

.#{$CN}__content {
	flex-grow: 1;
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 24px;
}

.#{$CN}__image {
	width: 140px;
}

.#{$CN}__text-wrapper {
	display: flex;
	gap: 8px;
	align-items: center;
}

.#{$CN}__text-icon {
	color: $colorPrimaryBase;
}

.#{$CN}__text {
	font-weight: 500;
	color: $colorPrimaryBase;
	margin: 0;
}
