$CN: 'exercise-image-content';

.#{$CN}__images-wrapper {
	display: grid;
	gap: 20px;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}


.#{$CN}__pic {
	position: relative;
	max-width: 400px;
	overflow: hidden;
	border-radius: 2px;
}

.#{$CN}__pic-close-btn {
	position: absolute;
	top: 5px;
	right: 5px;
	background: white;
	border: none;

	&:hover {
		background: white;
	}
}


.#{$CN}__transcript-collapse-text {
	display: inline-block;
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
	cursor: pointer;
	margin: 30px 0 8px 0;
}

.#{$CN}__transcript-collapse-triangle {
	transform: rotate(-90deg);
	transition: transform .1s;
	margin-left: 5px;
}

.#{$CN}__transcript-collapse-triangle--open {
	transform: rotate(0deg);
}

.#{$CN}__transcript-content {
	font-size: 14px;
	line-height: 22px;
}
