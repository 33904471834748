@import "../../app/App/css/variables";

$CN: toggle-block;

.#{$CN} {
	margin: 12px 0 0 0;
}

.#{$CN}__header {
	display: flex;
	cursor: pointer;
	user-select: none;
	gap: 6px;

	&--open {
		margin: 0 0 16px 0;
	}

	&--small {
		.#{$CN}__header-sign {
			width: 14px;
			height: 14px;
			margin: 4px 0 0 0;
		}

		.#{$CN}__header-text {
			@include Base__Base-Strong;
		}
	}

	&--medium {
		.#{$CN}__header-sign {
			width: 16px;
			height: 16px;
			margin: 5px 0 0 0;
		}

		.#{$CN}__header-text {
			@include LG__LG-Strong;
		}
	}
}

.#{$CN}__header-sign {
	transform: rotate(-90deg);

	&--open {
		transform: rotate(0deg);
	}
}

.#{$CN}__header-text {
	color: $colorText;
	margin: 0;
}

.#{$CN}__content {

}