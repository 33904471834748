$CN: no-group-tariff-card-wrapper;

.#{$CN} {
	cursor: pointer;
}

.#{$CN}__description {
	font-size: 14px;
	line-height: 22px;
	font-weight: 500;
	margin: 0 0 12px 0;
}