@import "../../../../../app/App/css/variables";

$CN: students-table;

.#{$CN}__form {
	display: flex;
	gap: 8px;
}

.#{$CN}__form {
	.ant-form-item {
		margin: 0;
	}
}

// ===================

.#{$CN}__text {
	@include Base__Base-Normal;
	color: $colorText;
	margin: 0;
}

.#{$CN}__name-wrapper {
	display: flex;
	align-items: center;
	gap: 8px;
}
