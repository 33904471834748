$CN: tariffs-page;

.#{$CN}__wrapper {
	flex-grow: 1;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 32px;
	overflow: auto;
}
