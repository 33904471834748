@import "../../../../../../app/App/css/variables";
@import "../../../../common/landing-common";

$CN: land-const-program-block;

.#{$CN} {
	display: flex;
	flex-direction: column;
	gap: 10px;
}
@include media-monitor() {
	.#{$CN} {
		flex-direction: row;
		gap: 24px;
	}
}

.#{$CN}__img-part {
	min-width: 58px;
	max-width: 58px;

	min-height: 58px;
	max-height: 58px;
}

.#{$CN}__text-part {
	flex-grow: 1;
}

.#{$CN}__header, .#{$CN}__text {
	color: white;
	margin: 0;
}

.#{$CN}__header {
	display: inline-block;
	font-size: 22px;
	font-weight: 600;
	line-height: 30px; /* 136.364% */
	letter-spacing: -0.55px;
}

.#{$CN}__header + .#{$CN}__text {
	margin-top: 10px;
}

.#{$CN}__text {
	font-size: 18px;
	font-weight: 500;
	line-height: 26px;
	letter-spacing: -0.3px;
}
