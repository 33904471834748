.text {
    color: rgba(5, 26, 46, 0.88);
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    margin: 0;
}

.link {
    display: inline-block;
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
    color: rgb(22, 119, 255);
    text-decoration: none;
    cursor: pointer;
    margin: 0;

    &:hover {
        color: rgb(0, 85, 208)
    }
}
