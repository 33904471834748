@import "../../../app/App/css/variables";

$CN: 'editable-description';

.#{$CN} {

}

.#{$CN}__icon {
	color: $colorIcon;
	margin: 0 0 0 5px;
}