.AdsBlock {
	height: auto;
	display: flex;
	margin: 40px 4px 0 4px;
	padding: 20px 12px;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 16px;
	position: relative;

	border-radius: 4px;
	background: var(--colorPrimaryBase, #1677ff);

	&__Sign {
		position: absolute;
		top: 12px;
		right: 8px;
		display: flex;
		width: 84px;
		height: 40px;
		transform: rotate(12.5deg);
		padding: 5px 8px;
		align-items: center;
		gap: 7px;
		flex-shrink: 0;

		border-radius: 3.11px;
		background: #fff;

		/* icon */
		box-shadow: 0px 2.35217px 2.21381px 0px rgba(0, 0, 0, 0.02),
			0px 5.65259px 5.32008px 0px rgba(0, 0, 0, 0.03),
			0px 10.64332px 10.01724px 0px rgba(0, 0, 0, 0.04),
			0px 18.98586px 17.86905px 0px rgba(0, 0, 0, 0.04),
			0px -2px 2px 0px rgba(0, 0, 0, 0.05) inset,
			0px -1px 1px 0px rgba(0, 0, 0, 0.1);
	}

	&__Text {
		color: var(--colorBorderBg, #fff);

		font-family: Montserrat;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 22px;
	}

	&__Button {
		width: 100%;
		align-self: center;
		color: var(--colorBorderBg, #fff);
		cursor: pointer;
		border-radius: 6px;
		border: 1px solid var(--colorBorderBg, #fff);
		background: var(--colorPrimaryBase, #1677ff);
		box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
		text-align: center;
	}
}

.Sign {
	&__Pin {
		position: absolute;
		top: -14px;
		left: 45px;
	}

	&__Logo {
		color: #fff;
		font-family: Cairo;
		font-size: 15.701px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		background-color: var(--colorPrimaryBase, #1677ff);
		border-radius: 9px;
		padding: 3px 4px;
	}

	&__Text {
		color: var(--colorTextHeading, rgba(5, 25, 46, 0.88));
		font-family: Cairo;
		font-size: 15.701px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}
}
