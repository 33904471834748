$CN: 'exercise-media';

/* ОСНОВНЫЕ ЧАСТИ */
.#{$CN}__label {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;

  & + * {
    margin-top: 8px;
  }
}

.#{$CN}__input-error {
  font-size: 14px;
  line-height: 20px;
  color: #EC5B57;
  margin: 5px 0 0 0;
}

/* БЛОК ЗАДАНИЯ К УПРАЖНЕНИЯ */
$CN-task: 'exercise-media-task';

.#{$CN-task}__wrapper {
  padding: 0 0 16px 0;
}

/* БЛОКИ С АУДИО */
$CN-medias: 'exercise-media-blocks';

.#{$CN-medias} {
  display: grid;
  gap: 20px;
}


/* БЛОК УДАЧНОЙ ЗАКАЧКИ ВИДЕО */
$CN-upload-success: 'exercise-media-success-upload';


/* БЛОК ПРОВАЛЕННОЙ ЗАКАЧКИ ФАЙЛА */
$CN-upload-error: 'exercise-media-error-upload';

.#{$CN-upload-error}__text {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: #87898E;
  margin: 0 0 10px 0;
}

/* КНОПКА ДОБАВЛЕНИЯ НОВОГО БЛОКА АУДИО */
$CN-add-button: 'exercise-media-add-media-block';

.#{$CN-add-button} {
  padding: 10px 0 0 0;
  margin: 20px 0 0 0;
  border-top: 1px solid #D9D9D9;
}

/* ФЛАГИ ОТВЕТА «Аудио» и «Письменный» */
$CN-answers: 'exercise-media-answers';

.#{$CN-answers} {
  display: flex;
  margin: 26px 0 0 0;
}

.#{$CN-answers}__label {
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
}

/* ПЕРКЛЮЧАТЕЛЬ РАСШИФРОВКИ */
$CN-transcript-toggle: 'exercise-media-transcript-toggle';

.#{$CN-transcript-toggle} {
  margin-top: 26px;
  display: flex;
  justify-content: space-between;
}

.#{$CN-transcript-toggle}__label {
  font-size: 14px;
  font-weight: 600;
}

/* ТЕКСТ РАСШИФРОВКИ */
$CN-transcript-input: 'exercise-media-transcript-input';

.#{$CN-transcript-input} {
  margin: 10px 0 0 0;
}
