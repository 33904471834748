$CN: 'exercise-true-false';

/* ОСНОВНЫЕ ЧАСТИ */
.#{$CN}__label {
  display: block;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;

  & + * {
    margin-top: 8px;
  }
}

.#{$CN}__question-mark {
	margin: 0 0 0 3px;
}

.#{$CN}__label--top-margin {
  display: block;
  margin-top: 16px;
}

.#{$CN}__input-error {
  font-size: 14px;
  line-height: 20px;
  color: #EC5B57;
  margin: 5px 0 0 0;
}

/* БЛОК УТВЕРЖДЕНИЙ */
$CN-statements: 'exercise-true-false-statements';

.#{$CN-statements}__help-text {
  margin: 5px 0 15px 0;
}

/* БЛОК УТВЕРЖДЕНИЯ */
$CN-statement: 'exercise-true-false-statement';

.#{$CN-statement} {
  display: flex;
}
.#{$CN-statement} + .#{$CN-statement} {
  margin-top: 20px;
}

.#{$CN-statement}__letter-part {
  padding: 5px 10px 0 0;
}

.#{$CN-statement}__content-part {
  flex-grow: 1;
	display: grid;
	gap: 10px;
}

.#{$CN-statement}__file-input-wrapper {
  //margin-top: 10px;
}

.#{$CN-statement}__correct-part {
  padding: 5px 0 0 10px;
}

.#{$CN-statement}__remove-button {
  margin: 0 0 0 16px;
}

/* БЛОК ССЫЛКИ НА ПОЛЕ СО ССЫЛКОЙ НА ВНЕШНЕЕ ВИДЕО */
$CN-video-link-input: 'exercise-true-false-video-link-input';

/* БЛОК ПРОВАЛЕННОЙ ЗАГРУЗКЕ ВИДЕО */
$CN-video-error-upload: 'exercise-true-false-image-error-upload';

.#{$CN-video-error-upload}__text {

}

/* КНОПКА ДОБАВЛЕНИЯ НОВОГО УТВЕРЖДЕНИЯ */
$CN-add-statement-button: 'exercise-true-false-add-statement';

.#{$CN-add-statement-button} {
  border-top: 1px solid #D9D9D9;
  padding: 10px 0 0 0;
  margin: 20px 0 0 0;
}
