$CN: exercises-aside;

.#{$CN} {

}

.#{$CN}__items {
  display: grid;
  gap: 16px;
}

.#{$CN}__item {
  display: flex;
  cursor: pointer;
	overflow: hidden;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  transition: background-color .3s;

  &:hover {
    border-color: #c4c4c4;
  }
}

.#{$CN}__item-image {
  width: 60px;
  height: 60px;
}

.#{$CN}__item-content {
  padding: 5px 10px;
}
.#{$CN}__item-header {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  margin-bottom: 0;
}
.#{$CN}__item-desc {
  font-size: 12px;
  line-height: 15px;
  color: #979798;
  margin-top: 2px;
  margin-bottom: 0;
}
