@import "../../../../../app/App/css/variables";

$CN: land-const-top-toolbar;

.#{$CN} {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1;
	display: flex;
	justify-content: space-between;
	width: 100%;
	background-color: white;
	padding: 10px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.#{$CN}__left {
	display: flex;
	gap: 8px;
	align-items: center;
}

.#{$CN}__save-status {
	font-size: 14px;
	font-weight: 500;
	color: gray;
}

.#{$CN}__middle {
	display: flex;
	gap: 12px;
}

.#{$CN}__right-part {
	display: flex;
	gap: 12px;
	align-items: center;
}
