$CN: 'exercise-audio-message-block';

.#{$CN} {
	text-align: center;
	background-color: #F1F3F4;
	padding: 30px 60px;
	border-radius: 2px;
}

.#{$CN}__header {
	font-size: 20px;
	line-height: 26px;
	font-weight: 600;
	margin: 0 0 0 0;
}

.#{$CN}__text {
	font-size: 16px;
	line-height: 24px;
	margin: 12px 0 0 0;
}
