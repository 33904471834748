@import "../../app/App/css/variables";

$CN: vertical-list;

.#{$CN} {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	column-gap: 16px;
	row-gap: 4px;
	@include SM__SM-Strong();
	color: $colorTextSecondary;
	padding: 0;
	margin: 0;
}

.#{$CN}__item:not(:last-child) {
	position: relative;

	&::after {
		content: '';
		position: absolute;
		top: 8px;
		right: -10px;
		width: 3px;
		height: 3px;
		background-color: $colorTextSecondary;
		border-radius: 50%;
	}
}

.#{$CN}__icon-wrapper {
	margin: 0 4px 0 0;
}

