@import "../../../../../app/App/css/variables";
@import "../../../common/landing-common";

$CN: landing-take-a-part-btn;

.#{$CN} {
	font-size: 20px;
	line-height: 28px;
	font-weight: 800;
	color: white;
	text-transform: uppercase;
	cursor: pointer;
	padding: 19px 30px;
	border: none;
	border-radius: 16px;

	@include allThemeColorClasses();

	&--colorScheme_1 {
		box-shadow: 0 4px 0 #E6A41D;
	}

	&--colorScheme_2 {

	}

	&--colorScheme_3 {

	}

	&--colorScheme_4 {

	}
}
