@import "../../../../app/App/css/variables";

$CN: staff-table;

.#{$CN}__text {
	@include Base__Base-Normal;
	color: $colorText;
	margin: 0;
}

.#{$CN}__name-wrapper {
	display: flex;
	align-items: center;
	gap: 8px;
}
