.topText {
	color: rgba(5, 26, 46, 0.88);
	text-align: center;
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
}

.blueBlock {
	display: flex;
	padding: 20px 30px;
	flex-direction: column;
	align-items: center;
	gap: 8px;
	background: #E6F4FF;
	border-radius: 8px;
}

.blueBlockText {
	color: rgba(5, 26, 46, 0.88);
	font-size: 12px;
	line-height: 20px;
	font-weight: 600;
}

.selectBlock {
	display: flex;
	justify-content: center;
	margin: 8px 0 0 0;
}