@import "../../../../../../../app/App/css/variables";
@import "../../../../../common/landing-common";

$CN: land-const-prog-bullets-sections;

.#{$CN} {
	display: grid;
	gap: 30px;
	align-items: flex-start;
	margin: 30px 0 0 0;
}
@include media-monitor() {
	.#{$CN} {
		margin: 60px 0 0 0;
	}
}