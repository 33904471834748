@import "../../../../../app/App/css/variables";

$CN: edit-lesson-card;

.#{$CN} {
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 12px;
}

.#{$CN}__action {
	padding: 0;
	color: $colorText;
	@include Base__Base-Normal;

	&:hover {
		span {
			background-color: transparent;
		}
	}
}

@media (min-width: 550px) {
	.#{$CN} {
		flex-direction: row;
		padding: 20px;
	}
}

.#{$CN}__cover-wrapper {
	flex-grow: 10;
	width: 100%;
	height: 134px;
}

@media (min-width: 550px) {
	.#{$CN}__cover-wrapper {
		width: 100px;
		max-width: 100px;
		height: 100px;
	}
}

.#{$CN}__content {
	flex-grow: 1;
	flex-shrink: 100;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.#{$CN}__name {
	@include Heading__Heading-5;
	color: $colorTextHeading;
	margin: 8px 0 0 0;
}

.#{$CN}__description {
	@include Base__Base-Normal;
	color: $colorTextSecondary;
	margin: 4px 0 0 0;
}

.#{$CN}__buttons {
	display: flex;
	flex-wrap: wrap;
	column-gap: 4px;
	row-gap: 4px;
	justify-content: space-between;
	margin: 8px 0 0 0;
}

.#{$CN}__left-buttons {
	display: flex;
	flex-wrap: wrap;
	column-gap: 4px;
	row-gap: 4px;
}