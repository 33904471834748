@import '../../../../app/App/css/adaptive';

.Avatar {
	@include size-tablet {
		width: 24px;
		height: 24px;
	}
}
.AnswersFeed {
	width: 100%;
	padding-bottom: 40px;
}
.RepliesContainer {
	width: 100%;
	min-height: 100%;
	padding: 0 40px;
	@include size-tablet {
		padding: 0 10px;
	}
}
.InnerReplies {
	display: flex;
	flex-direction: column;
	margin-top: -20px;
	background-color: #fff;
	padding-left: 45px;
	@include size-tablet {
		padding-left: 30px;
	}
}

.HeaderContainer {
	display: flex;
	width: 100%;
	padding: 30px 40px 0;
	margin-bottom: 60px;
	justify-content: space-between;
	align-items: baseline;
	h2 {
		font-size: 30px;
	}
}

.MessagesContainer {
	width: 100%;
	background-color: #fff;
	padding: 20px;
	border-radius: 4px;
	@include size-tablet {
		padding: 10px;
	}
}

.MessageContent {
	display: grid;
	grid-template-columns: 40px auto;
	@include size-tablet {
		grid-template-columns: 30px auto;
	}
}

.BreadCrumbs {
	color: #64748b;
	// margin-bottom: 10px;
}

.Message {
	position: relative;
	background: #f7f8f9;
	border-radius: 10px;
	padding: 8px 8px 8px 15px;
	&_Head {
		display: flex;
		gap: 10px;
		align-items: baseline;
	}
	&_Top {
		display: flex;
		justify-content: space-between;
		margin-bottom: 5px;
		align-items: baseline;
		width: 100%;
	}
	&_Bottom {
		position: relative;
		width: 100%;
		display: flex;
		align-items: baseline;
		justify-content: space-between;

		button {
			left: -10px;
		}
	}
	&_Content {
		padding: 5px 0 0;
		width: 100%;
		margin-bottom: 15px;
	}
	@include size-tablet {
		padding: 5px 10px;
		margin-bottom: 10px;
	}
}

.Author {
	display: inline-block;
	font-weight: bold;
	@include size-tablet {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.Time {
	display: inline-block;
	padding-right: 5px;
	color: #64748b;
	margin-left: auto;
}

.ReplyForm {
	width: 100%;
	display: grid;
	background-color: #fff;
	grid-template-columns: calc(100% - 45px) 45px;
	padding: 20px 20px 20px;
	margin-bottom: 30px;
	border-radius: 0 0 5px 5px;
	@include size-tablet {
		padding: 15px;
	}
}

.Nested {
	margin: 0;
	margin-bottom: -20px;
}

.Line {
	&:before {
		position: absolute;
		bottom: 0;
		left: 10px;
		border-left: 1px solid #d0d5dd;
	}
}

.MessageHeader {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	position: absolute;
	top: 0;
	left: 0;
	padding: 0 0 0 15px;
	width: 100%;
}
