@import "../../../../../../app/App/css/variables";
@import "../../../../common/landing-common";

$CN: landing-time-block;

.#{$CN} {
	display: flex;
	gap: 18px;
	padding: 10px 10px 10px 30px;
	border-radius: 16px;
	border: 1px solid #D5D7DC;
}

@include media-monitor() {
	.#{$CN} {
		padding: 20px 10px 20px 30px;
	}
}

.#{$CN}__counter {
	font-size: 36px;
	font-weight: 700;
	line-height: 44px;
	padding: 0 2px 0 0;
	margin: 0;

	@include allThemeGradientClassesForText();
}

.#{$CN}__text {
	color: #2C2A35;
	font-size: 22px;
	font-weight: 600;
	line-height: 30px;
	white-space: pre-wrap;
	margin: 0;
}
