@import "../../../../../app/App/css/variables";

$CN: payment-form;

.#{$CN} {

}

.#{$CN}__left-part {
	display: flex;
	flex-direction: column;
	gap: 24px;
	max-width: 200px;
}

.#{$CN}__header {
	@include Heading__Heading-5
}
