@import "../../../../app/App/css/variables";

$CN: student-history-table;

.#{$CN} {

}

.#{$CN}__top {
	display: flex;
	gap: 12px;
	align-items: center;
}

.#{$CN}__action-wrapper {
	display: flex;
	gap: 4px;
	align-items: center;
}
