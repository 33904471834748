@import "../../../../../../app/App/css/variables";

$CN: land-const-tariffs-section;

.#{$CN} {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100px;
}
