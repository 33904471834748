@import "../../../../../../app/App/css/variables";


$CN: tariff-card-wrapper;

.#{$CN} {
	position: relative;

	&:hover {
		.#{$CN}__delete-button {
			display: block;
		}
	}
}

.#{$CN}__delete-button {
	position: absolute;
	display: none;
	top: -11px;
	right: -11px;
	color: $colorError;

	&.ant-btn-default:hover {
		color: $colorError;
		border-color: $colorError;
	}
}
