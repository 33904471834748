.topText {
	color: rgba(5, 25, 46, 0.88);
	text-align: center;
	font-size: 20px;
	font-weight: 500;
	line-height: 28px;
}

.blueBlock {
	padding: 30px;
	border-radius: 8px;
	background: #E6F4FF;
}

.blueBlockTop {
	color: rgba(5, 25, 46, 0.65);
	text-align: center;
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	margin: 0;
}

.blueBlockBottom {
	color: #1677FF;
	text-align: center;
	font-size: 24px;
	font-weight: 600;
	line-height: 32px;
	margin: 8px 0 0 0;
}

.blueBlockBottomButton {
	display: flex;
	justify-content: center;
	margin: 16px 0 0 0;
}