@import "../../../../app/App/css/variables";

$CN: student-orders;

.#{$CN} {

}

.#{$CN}__stats {
	display: flex;
	gap: 12px;
	align-items: center;
}
