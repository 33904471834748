@import '../../../app/App/css/variables.scss';

.ExpressCourses {
	width: 100%;
	padding: 40px 10px;

	@media only screen and (min-device-width: 768px) {
		padding: 40px;
	}

	&__CoursesList {
		width: 100%;
		padding: 24px 0;
		display: flex;
		flex-direction: column;
		gap: 24px;

		@media only screen and (min-device-width: 768px) {
			flex-direction: row;
			overflow-x: hidden;
		}
	}
}
