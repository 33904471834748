$CN: live-lesson-reg-page;

.#{$CN} {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100vh;
	padding: 56px;
	background: #DFE4EA;
}

.#{$CN}__logo {
	width: 100px;
}

.#{$CN}__content {
	flex-grow: 1;
	display: flex;
	align-items: center;
	max-width: 560px;
}
