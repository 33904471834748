@import "../../../../../app/App/css/variables.scss";

$CN: openlessons-form;

.#{$CN} {
	flex-grow: 1;
}

.#{$CN}__title {
  @include Heading__Heading-4;
  color: $colorText;
}

.#{$CN}__subtitle {
  @include SM__SM-Normal;
	color: $colorTextSecondary;
}

.#{$CN}__lessonslist {
  padding: 32px 0;
}

.ant-form-item {
  margin-bottom: 0;
}