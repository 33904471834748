.wrapper {
  width: 100%;
  height: 40px;
  padding: 8px 24px 0 24px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.selectLang {
  width: 300px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}