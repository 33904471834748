@font-face {
    font-family: "Montserrat";
    src: local("Montserrat"),
        url("../../../../public/fonts/Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: local("Montserrat"),
        url("../../../../public/fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: local("Montserrat"),
        url("../../../../public/fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}


@font-face {
    font-family: "Montserrat";
    src: local("Montserrat"),
        url("../../../../public/fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}


@font-face {
    font-family: "Montserrat";
    src: local("Montserrat"),
        url("../../../../public/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}


@font-face {
    font-family: "Montserrat";
    src: local("Montserrat"),
        url("../../../../public/fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}
