.html-editor-rewrite {

	.ql-container {
		font-family: var(--font-montserrat) !important;
		font-size: 15px;
		line-height: 21px;

		h1, h2, h3, p {
			margin: 0;
		}

		h1 {
			font-size: 40px;
			line-height: 48px;
			font-weight: 600;
		}

		h2 {
			font-size: 30px;
			line-height: 38px;
			font-weight: 600;
		}

		h3 {
			font-size: 24px;
			line-height: 32px;
		}

		h1 + p {
			margin-top: 8px;
		}
		h2 + p {
			margin-top: 8px;
		}
		h3 + p {
			margin-top: 8px;
		}
		p + p {
			margin-top: 8px;
		}

		h1 + h2 {
			margin-top: 8px;
		}
		h2 + h2 {
			margin-top: 8px;
		}
		h3 + h2 {
			margin-top: 8px;
		}
		p + h2 {
			margin-top: 8px;
		}

		h1 + h3 {
			margin-top: 8px;
		}
		h2 + h3 {
			margin-top: 8px;
		}
		h3 + h3 {
			margin-top: 8px;
		}
		p + h3 {
			margin-top: 8px;
		}
	}
}