.ItemAction {
	position: relative;

	&__Mobile {
		width: 100%;
		height: auto;
		position: absolute;
		bottom: 0;
		left: 0;

		@media only screen and (min-device-width: 768px) {
			display: none;
		}
	}

	&__Drawer {
		border-radius: 8px;

		:global(.ant-drawer-body) {
			padding: 10px;
		}
	}

	&__ContentWrapper {
		height: auto !important;
	}

	&__MenuItem {
		height: 28px;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		gap: 6px;
		padding: 4px 8px;
		margin: 4px 0;

		&:hover {
			background-color: var(--Placeholder, rgba(196, 196, 208, 0.2));
			border-radius: 4px;
		}
	}

	&__MenuIcon {
		font-size: 16px;
	}

	&__MenuTitle {
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 142.857% */
		letter-spacing: -0.4px;
	}

  &__MenuDivider {
    height: 2px;
    border-bottom: 1px solid var(--Placeholder, rgba(196, 196, 208, 0.2));
  }

	&__DownlaodLink {
		color: #000;
		text-decoration: none;
	}
}
