@import "../../../app/App/css/variables";

$CN: leads-stats;

.#{$CN} {
	display: flex;
	flex-direction: row;
	gap: 24px;
	overflow: auto;
}
