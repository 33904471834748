@import "../../../../../../app/App/css/variables";
@import "../../../../common/landing-common";

$CN: p-tariff-card;

.#{$CN} {
	width: 100%;
	max-width: 350px;
}

.#{$CN}__card-wrapper {

}

.#{$CN}__card {
	padding: 30px 20px;
	border: 1px solid #D5D7DC;
	border-radius: 20px;

	@include allThemeColorClasses();
}

@media (min-width: 600px) {
	.#{$CN}__card {
		padding: 32px;
	}
}