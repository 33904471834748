@import "../../../../../../../app/App/css/variables";

$CN: main-payment-tariff-block;

.#{$CN}__payments {
	display: grid;
	gap: 24px;
}

.#{$CN}__bottom {
	margin: 24px 0 0 0;
}
