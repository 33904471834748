@import '../../../../app/App/css/adaptive';

.Menu {
	display: none;
	margin-bottom: 20px;

	@include size-tablet {
		display: block;
	}
}

.MenuItem {
	width: 100%;
	display: flex;
	justify-content: space-between;
}
