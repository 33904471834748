@import "../../app/App/css/variables";

$CN: image;

.#{$CN} {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	overflow: hidden;
	background-color: $colorPrimaryBg;
}

.#{$CN}__image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.#{$CN}__icon {
	font-size: 36px;
	color: $colorPrimary;
}
