@import "../../../../../../app/App/css/variables";
@import "../../../../common/landing-common";

$CN: land-const-ways-section;

.#{$CN} {
	display: grid;
	gap: 20px;
}
@include media-monitor() {
	.#{$CN} {
		gap: 40px;
	}
}
