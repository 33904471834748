@import "../../App/css/variables";

$CN: user-menu-plan;

.#{$CN} {
	display: flex;
	align-items: center;
	background-color: rgba(230, 244, 255, 1);
	height: 40px;
	padding: 0 32px 0 18px;
	margin-right: -30px;
	border-radius: 20px 0 0 20px;
}

.#{$CN}__text {
	font-size: 12px;
	line-height: 20px;
}
.#{$CN}__text-left {
	color: rgba(5, 25, 46, 0.65);
	font-weight: 500;
	margin-right: -8px;
}
.#{$CN}__text-right {
	color: rgba(5, 26, 46, 0.88);
	font-weight: 600;
	margin-left: -8px;
}
.#{$CN}__tariff-sign {
	transform: scale(.5);
}