:root {
    --text-color: rgb(30, 30, 30);

    --color-gray-60: #F6F8FA;
    --color-gray-70: #F8F8F8;
    --color-gray-75: #E2E7ED;
    --color-gray-80: #E6E6E6;
    --color-gray-81: #EBEBEB;
    --color-gray-85: #EBEFF2;
    --color-gray-90: #E6E7E9;
    --color-gray-91: #E7EAEE;
    --color-gray-95: #B7BECD;
    --color-gray-100: #BFBFBF;
    --color-gray-105: #C0C0C1;
    --color-gray-110: #ADADAD;
    --color-gray-120: #97A0B0;
    --color-gray-150: #979798;
    --color-gray-160: #858586;
    --color-gray-170: #87898E;
    --color-gray-200: #8692A6;
    --color-gray-250: #72849A;
    --color-gray-255: #7E7E7E;
    --color-gray-260: #777677;
    --color-gray-270: #626262;
    --color-gray-300: #696F79;
    --color-gray-320: #515365;
    --color-gray-350: rgba(115, 133, 156, 0.4);
    --color-gray-400: #455360;
    --color-gray-500: #80808036;
    --color-gray-510: #333333;
    --color-gray-550: #3C3C43;
    --color-gray-600: #343535;

    --color-white-0: #FFFFFF;
    --color-white-50: #F1F1F1;
    --color-white-55: #F3F6F8;
    --color-white-100: #F5F5F5;
    --color-white-105: #F6FFED;
    --color-white-110: #FAFAFA;
    --color-white-150: #FCFCFD;
    --color-white-200: #F0F0F0;
    --color-white-201: #D7DFE4;
    --color-white-210: #D9D9D9;
    --color-white-250: #DEDEDE;
    --color-white-300: #DFEFFF;

    --color-yellow-290: #F9C74F;
    --color-yellow-300: #FADB14;

    --color-green-150: #EBF8F1;
    --color-green-155: #B7EB8F;
    --color-green-160: #9BD3B9;
    --color-green-200: #51DD78;
    --color-green-250: #05A660;
    --color-green-300: #2B981A;

    --color-red-100: #FFEBEB;
    --color-red-105: #FFF1F0;
    --color-red-110: #FF8987;
    --color-red-200: #FF4D4F;
    --color-red-210: #F94144;
    --color-red-250: #E53535;

    --color-blue-8: #E2F5FF;
    --color-blue-9: #E6F7FF;
    --color-blue-10: #DFEFFF;
    --color-blue-90: #1DABF2;
    --color-blue-95: #40A9FF;
    --color-blue-96: #3D9FFB;
    --color-blue-100: #1890FF;
    --color-blue-140: rgba(29, 171, 242, 0.3);
    --color-blue-150: rgba(29, 171, 242, 0.5);
    --color-blue-200: #1565D8;
    --color-blue-300: #385B94;
    --color-blue-400: #273767;
    --color-blue-600: #06192D;
    --color-blue-700: #23253C;

    --color-dark-40: #23262F;
    --color-dark-45: rgba(0, 0, 0, 0.05);
    --color-dark-46: rgba(0, 0, 0, 0.25);
    --color-dark-50: rgba(0, 0, 0, 0.043);
    --color-dark-55: rgba(0, 0, 0, 0.1);
    --color-dark-60: rgba(0, 0, 0, 0.016);
    --color-dark-100: rgba(0, 0, 0, 0.11);
    --color-dark-150: rgba(0, 0, 0, 0.12);
    --color-dark-200: #1F1F1F;
    --color-dark-210: #2C2C2C;
    --color-dark-300: #262626;
    --color-dark-310: #292935;
    --color-dark-350: #212121;
    --color-dark-351: rgba(0, 0, 0, 0.200577);
    --color-dark-360: rgba(0, 0, 0, 0.65);
    --color-dark-400: rgba(0, 0, 0, 0.85);
    --color-dark-410: #060302;
    --color-dark-420: #010101;
    --color-dark-500: #000000;

    --dark: #181f30;
    --dark-85: rgba(24, 31, 48, 0.70);

    --icon: #ACB4C0;
    --neutral: #DFE4EA;
    --text-color-red: #ff0000;
    --text-color-green: #08b53c;
    --background-color: #c2c5d8;
    --dark-blue: #3f92f8;
    --paragraph: #4B5265;
    --platform-border-color: #c2c5d8;
    --secondary: #EE3465;
    --secondary-light: #f04874;
    --transition-default: all 0.2s ease-out;
    --border-color: #e6ecf5;
    --placeholder: rgba(196, 196, 208, 0.20);
    --placeholder-grey: #888da8;
    --button-grey-bg: #9a9fbf;
    --primary: #2573E6;
}

$colorBgLayout: #F8F9FC;
$colorPrimary: #1677FF;
$colorFillAlter: rgba(5, 25, 46, .02);
$colorFullContent: rgba(5, 23, 85, .06);
$colorFullSecondary: rgba(5, 15, 46, .06);
$colorFillContentHover: rgba(5, 25, 46, .15);
$colorSplit: rgba(5, 25, 46, .06);
$colorBorder: rgb(5, 25, 46, .15);
$colorBorderSecondary: rgb(5, 25, 46, .06);
$colorIcon: rgba(5, 25, 46, .45);
$colorTextTertiary: rgba(5, 25, 46, .45);
$colorTextDescription: rgba(5, 25, 46, .45);
$colorTextSecondary: rgba(5, 25, 46, .65);
$colorTextLabel: rgba(5, 25, 46, .65);
$colorTextHeading: rgba(5, 25, 46, .88);
$colorText: rgba(5, 25, 46, .88);
$colorBgContainer: rgb(255, 255, 255);
$colorPrimaryBase: rgb(22, 119, 255);
$colorPrimaryBg: rgb(230, 244, 255);
$colorError: #FF4D4F;
$colorPrimaryBorder: rgb(145, 202, 255);
$colorPrimaryBorderHover: rgb(105, 177, 255);
$colorPrimaryActive: rgb(9, 88, 217);
$colorSuccessBorderHover: #95DE64;
$colorGold6: #FAAD14;
$colorOrange1: #FFF7E6;
$colorOrange3: #FFD591;
$colorOrange6: #FA8C16;
$colorOrange7: #D46B08;
$colorGreen1: #F6FFED;
$colorGreen2: #D9F7BE;
$colorGreen3: #B7EB8F;
$colorGreen5: #73D13D;
$colorGreen6: #52C41A;
$colorGreen7: #389E0D;
$colorGreen8: #237804;
$colorPurple4: #B37FEB;
$colorPurple6: #722ED1;
$colorPurple7: #531DAB;
$colorMagenta1: #FFF0F6;
$colorMagenta3: #FFADD2;
$colorMagenta6: #EB2F96;
$colorBlue1: #E6F4FF;
$colorBlue3: #91CAFF;
$colorBlue6: #1677FF;
$colorLink: #1677FF;
$controlItemBgActive: #E6F4FF;
$controlItemBgActive: #E6F4FF;
$colorInfoBg: #E6F4FF;
$colorSuccess: #52C41A;
$colorErrorBase: #FF4D4F;

@mixin Heading__Heading-2() {
	font-size: 30px;
	line-height: 38px;
	font-weight: 600;
}

@mixin Heading__Heading-3() {
	font-size: 24px;
	line-height: 32px;
	font-weight: 600;
}

@mixin Heading__Heading-4() {
	font-size: 20px;
	line-height: 28px;
	font-weight: 600;
}

@mixin Heading__Heading-5() {
	font-size: 16px;
	line-height: 24px;
	font-weight: 600;
}

@mixin SM__SM-Normal() {
	font-size: 12px;
	line-height: 20px;
	font-weight: 500;
}

@mixin SM__SM-Strong() {
	font-size: 12px;
	line-height: 20px;
	font-weight: 600;
}

@mixin Base__Base-Normal() {
	font-size: 14px;
	line-height: 22px;
	font-weight: 500;
}

@mixin Base__Base-Strong() {
	font-size: 14px;
	line-height: 22px;
	font-weight: 600;
}

@mixin LG__LG-Strong() {
	font-size: 16px;
	line-height: 24px;
	font-weight: 600;
}

@mixin XL__XL-Strong() {
	font-size: 20px;
	line-height: 28px;
	font-weight: 600;
}

$navbarHeight: 55px