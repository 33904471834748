@import "../../../../../app/App/css/variables";
@import "../../../common/landing-common";

$CN: land-const-controls-frame;

.#{$CN} {
	position: relative;

	&:hover .#{$CN}__btn {
		display: block;
	}
}

.#{$CN}__btn {
	position: absolute;
	z-index: 1;
	display: none;

	/* left - center - right */
	/*
		top
		center
		bottom
	*/
	&--center-top {
		left: 50%;
		top: 0;
		transform: translateX(-12px) translateY(-12px);
	}

	&--right-top {
		right: 0;
		top: 0;
		transform: translateX(12px) translateY(-12px);
	}

	&--right-center {
		right: 0;
		top: 50%;
		transform: translateX(-12px) translateY(-12px);
	}

	&--right-bottom {
		right: 0;
		bottom: 50%;
		transform: translateX(-12px) translateY(-12px);
	}

	&--center-bottom {
		left: 50%;
		bottom: 0;
		transform: translateX(-12px) translateY(12px);
	}

	&--left-center {
		left: 0;
		top: 50%;
		transform: translateX(-12px) translateY(-12px);
	}
}
