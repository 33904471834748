@import "../../../../app/App/css/variables";

$CN: no-groups-info;

.#{$CN} {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	padding: 20px;
}

.#{$CN}__illustration {
	width: 150px;
}

.#{$CN}__text {
	@include Heading__Heading-5;
	margin: 0;
}
