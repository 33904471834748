$CN: recover-pass-form-step;

.#{$CN} {
	color: #979798;
	text-align: center;
	margin: 10px 0 0 0;
}

.#{$CN}__counter {
	position: relative;
	font-size: 12px;
	line-height: 18px;
	margin: 0 0 10px 0;

	&::before {
		content: '';
		position: absolute;
		top: 10px;
		left: 0;
		right: 0;
		height: 1px;
		background: #D9D9D9;
	}

	span {
		position: relative;
		z-index: 1;
		display: inline-block;
		background: white;
		padding: 0 20px;
	}
}

.#{$CN}__message {
	font-size: 16px;
	line-height: 26px;
	margin: 0;
}

.#{$CN}__center-button {
	margin: 10px 0 0 0;
}