@import 'app/App/css/variables';

.LiveStorage {
	flex: 0 1 calc(100vh - 135px);
	border-radius: 8px;
	background: var(--color-white-0);
	box-shadow: 0px 2px 4px 0px rgba(34, 52, 71, 0.03),
		0px 1px 6px -1px rgba(34, 52, 71, 0.03),
		0px 0px 1px 1px rgba(34, 52, 71, 0.05);
	display: flex;
	flex-direction: column;

	@media only screen and (min-device-width: 768px) {
		width: 100%;
		height: calc(100vh - 132px);
	}

	@media only screen and (max-device-width: 768px) {
		position: absolute;
		left: 10px;
		top: 60px;
		right: 10px;
		width: calc(100vw - 20px);
		height: calc(100vh - 150px);
		z-index: 99;
	}

	&__StorageHeader {
		@media only screen and (min-device-width: 768px) {
			display: flex;
			flex-direction: row;
			padding: 16px 10px;
			justify-content: space-between;
			align-items: center;
		}

		@media only screen and (max-device-width: 768px) {
			display: none;
		}
	}

	&__StorageBody {
		margin: 6px;
		border-radius: 8px;
		overflow: hidden;

		@media only screen and (max-device-width: 768px) {
			display: none;
		}
	}

	&__StorageBodyMobile {
		padding: 0 10px;
		overflow-y: auto;

		@media only screen and (min-device-width: 768px) {
			display: none;
		}
	}
}

.StorageHeader {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	@media only screen and (min-device-width: 768px) {
		padding: 16px 10px;
	}

	@media only screen and (max-device-width: 768px) {
		width: 100%;
		height: 64px;
		padding: 16px 8px;
	}

	&__FolderName {
		@include LG__LG-Strong;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		gap: 6px;
	}

	&__Actions {
		display: flex;
		justify-content: space-between;
		gap: 6px;

		@media only screen and (max-device-width: 768px) {
			display: none;
		}
	}

	&__MobileActions {
		display: flex;
		justify-content: space-between;
		gap: 6px;

		@media only screen and (min-device-width: 768px) {
			display: none;
		}
	}

	&__ActionLabel {
		font-size: 16px;
		font-weight: 500;
		letter-spacing: -0.4px;
	}
}

.FolderPath {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;

	@media only screen and (max-device-width: 768px) {
		padding-left: 10px;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 20px; /* 125% */
		letter-spacing: -0.4px;
	}
}

.FolderName {
	cursor: pointer;
}
