$CN: menu-sider;

.#{$CN} {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	& .ant-layout-sider-trigger {
		height: 40px;
		background-image: url('images/MenuFold.svg');
		background-repeat: no-repeat;
		background-position: 16px 50%;
		cursor: pointer;
		border-top: 1px solid var(--color-white-200);
	}

	.anticon {
		display: none;
	}
}
