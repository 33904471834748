@import "../../../../app/App/css/variables";

$CN: employee-main-article;

.#{$CN}__user-name {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 10px;
}

.#{$CN}__user-activation-mark {
	width: 20px;
}