@import "../../../../../../app/App/css/variables";
@import "../../../../common/landing-common";

$CN: landing-time-section-content;

.#{$CN} {
	display: grid;
	gap: 30px;
	align-items: flex-start;
}
@include media-monitor() {
	.#{$CN} {
		grid-template-columns: 1fr 360px;
	}
}

.#{$CN}__content {
	display: grid;
	gap: 30px;
}
