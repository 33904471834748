@import "../../../../../../app/App/css/variables";

$CN: student-training-card;

.#{$CN} {
}

.#{$CN}__container {

}

.#{$CN}__top {
	display: flex;
	flex-direction: column;
	gap: 10px;

	@media (min-width: 400px) {
		flex-direction: row;
	}
}

.#{$CN}__group-name {
	@include SM__SM-Normal;
	color: $colorTextSecondary;
	margin: 0;
}

.#{$CN}__training-name {
	@include Heading__Heading-5;
	color: $colorTextSecondary;
	margin: 0;

	&--active {
		color: $colorTextHeading;
	}
}

.#{$CN}__description {
	@include Base__Base-Normal;
	color: $colorTextSecondary;
	margin: 4px 0 0 0;
}

.#{$CN}__window {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100px;
	text-align: center;
	background: $colorBgLayout;
	padding: 16px 16px;
	margin: 8px 0 0 0;
	border-radius: 4px;
}

.#{$CN}__window-inner {
	flex-grow: 1;
	display: grid;
	gap: 8px;
}

.#{$CN}__window-small-text {
	@include SM__SM-Normal;
	color: $colorTextSecondary;
	margin: 8px 0 0 0;
}

.#{$CN}__window-text {
	@include SM__SM-Strong;
	color: $colorText;
	margin: 0;
}

.#{$CN}__bottom {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 12px;
	margin: 8px 0 0 0;
}

@media (min-width: 700px) {
	.#{$CN}__bottom {
		flex-direction: row;
		justify-content: space-between;
		margin-top: 12px;
	}
}

.#{$CN}__next-date-text {
	display: flex;
	@include SM__SM-Strong;
	color: $colorText;
	text-align: center;
	margin: 0;

	&--gray {
		color: $colorTextSecondary;
	}
}

.#{$CN}__next-date-icon {
	width: 18px;
	color: $colorIcon;
	margin: 0 4px 0 0;
}
