@import "../../../../../app/App/css/variables";
@import "../../../common/landing-common";

$CN: land-const-section-header;

.#{$CN} {
	display: grid;
	gap: 10px;
	text-align: center;
	width: 100%;
}

.#{$CN}__image {
	max-width: 80px;
}

.#{$CN}__header {
	font-size: 56px;
	font-weight: 700;
	line-height: 62px;
	letter-spacing: -2.4px;
	margin: 0;

	&--white {
		color: white;
	}

	@include allThemeGradientClassesForText();
}
