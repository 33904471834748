@import "../../../../app/App/css/variables";

$CN: employee-header-buttons-list;

.#{$CN}__icon {
	color: $colorIcon;
	margin: 0 5px 0 0;
}

.#{$CN}__danger {
	color: $colorErrorBase;

	.#{$CN}__icon {
		color: $colorErrorBase;
	}
}
