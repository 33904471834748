@import "../../../../../app/App/css/variables";
@import "../../../common/landing-common";

$CN: landing-section-wrapper;

.#{$CN} {
	display: flex;
	flex-direction: column;
	gap: 30px;
}
@include media-monitor() {
	.#{$CN} {
		gap: 60px;
	}
}
