.Course {
  width: 100%;
  padding: 40px 10px;

  @media only screen and (min-device-width: 768px) {
		padding: 40px;
	}

  &__ExercisesList {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    padding: 24px 0;
  }
}