@import "../../../../app/App/css/variables";

$CN: add-employee-to-group;

.#{$CN}__stuff {
	background-color: white;
	overflow: hidden;
	padding: 0;
	margin: 12px 0 0 0;
	border-radius: 8px;
	border: 1px solid #e5e5e5;
}

.#{$CN}__employee {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	cursor: pointer;
	padding: 8px 12px;

	&:hover {
		background-color: $colorBgLayout;
	}
}

.#{$CN}__employee-avatar {
	width: 24px;
	height: 24px;
	border-radius: 50%;
}

.#{$CN}__employee-name {
	@include Base__Base-Normal;
	flex-grow: 1;
	margin: 0;

	&--strong {
		@include Base__Base-Strong;
	}
}

.#{$CN}__employee-role {
	@include Base__Base-Normal;
	color: $colorTextSecondary;
	width: 90px;
	margin: 0;
}

.#{$CN}__employee-team-icon {
	color: $colorSuccess;
}

.#{$CN}__employee-group-icon {
	color: $colorIcon;
}

.#{$CN}__employee-counter {
	@include SM__SM-Normal;
	margin: 0;
}

.#{$CN}__employee-check {
	visibility: hidden;
	color: $colorPrimaryBase;
}

.#{$CN}__employee-check--visible {
	visibility: visible;
}
