@import "../../../../../../app/App/css/variables";
@import "../../../../common/landing-common";

$CN: land-const-existence-main-img;

.#{$CN} {
	display: flex;
	align-items: flex-end;
	overflow: hidden;
	border-radius: 20px;

	@include allThemeGradientClasses();
}
@include media-monitor() {
	.#{$CN} {

	}
}
