$CN: auth-content-wrapper;

.#{$CN} {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 20px;
	background: white;
	overflow: auto;
	padding: 20px;
}
@media (min-width: 500px) {
	.#{$CN} {
		padding: 60px;
		margin: 5px;
		border-radius: 8px;
	}
}

.#{$CN}__top {
	width: 100%;
	max-width: 420px;
	margin: 0 auto;
}

.#{$CN}__back-link {
	display: none;
}
.#{$CN}__top-logo {
	width: 130px;
}
@media (min-width: 800px) {
	.#{$CN}__back-link {
		display: block;
	}
	/*.#{$CN}__top-logo {
		display: none;
	}*/
}

.#{$CN}__logo {
	width: 130px;
}

.#{$CN}__middle {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	max-width: 500px;
	margin: 0 auto;
}

@media (min-width: 1000px) {
	.#{$CN}__middle {
		padding: 0 40px;
	}
}

.#{$CN}__image-above {
	margin: 0 auto 20px;
}

.#{$CN}__header {
	font-size: 25px;
	line-height: 32px;
	text-align: center;
	font-weight: 700;
	margin: 0;
}
@media (min-width: 500px) {
	.#{$CN}__header {
		font-size: 38px;
		line-height: 46px;
	}
}

.#{$CN}__header-desc {
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: #979798;
	margin: 5px 0 0 0;
}
@media (min-width: 500px) {
	.#{$CN}__header-desc {
		font-size: 18px;
		line-height: 24px;
		margin: 10px 0 0 0;
	}
}

.#{$CN}__content {
	width: 100%;
	margin: 30px auto 0 auto;
}

.#{$CN}__bottom {

}

.#{$CN}__bottom-text {
	font-size: 15px;
	line-height: 21px;
	text-align: center;
	margin: 0;
}
