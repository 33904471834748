@import "../../../../../../app/App/css/variables";
@import "../../../../common/landing-common";

$CN: landing-ways-section-header;

.#{$CN} {
	font-size: 30px;
	line-height: 32px;
	font-weight: 700;
	letter-spacing: -1px;
	text-align: center;
	white-space: pre-wrap;
	margin: 0;

	@include allThemeGradientClassesForText();
}
@include media-monitor() {
	.#{$CN} {
		font-size: 36px;
		line-height: 44px;
	}
}