@import "../../../../../../app/App/css/variables";

// Стиль редактируемого текста
@mixin tariff-ghost-text {
	cursor: text;
	border-radius: 4px;
	border: 1px dashed transparent;
	transition: border-color .2s;

	&:hover {
		border-color: $colorPurple4;
	}

	&:focus {
		outline: none;
		border-color: $colorPurple6;
	}

	&--empty {
		&::after {
			color: rgba(0, 0, 0, .25);
		}
	}
}
