.Answer {
	width: 500px;
	text-align: center;
	background: #ffffff;
	color: #515365;
}

.Answer__Input {
	height: 90px;
	border: 1px solid var(--Neutral, #dfe4ea);
	border-radius: 4px;
}
