@import "../../../common/landing-common";

$CN: land-const-take-a-part;

.#{$CN} {
	display: flex;
	justify-content: center;
	margin: 30px 0 0 0;
}
@include media-monitor() {
	.#{$CN} {
		margin: 60px 0 0 0;
	}
}
