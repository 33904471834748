@import "app/App/css/variables";
$CN: ui-drawer-wrapper;


.#{$CN} {
    .ant-drawer-header-title {
        flex-direction: row-reverse
    }
    .ant-drawer-title {
        color: $colorTextHeading;
    }
    .ant-drawer-close{
        margin-inline-end: 0;
    }
}