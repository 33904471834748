@import "../../../../../../app/App/css/variables";
@import "../../../../common/landing-common";

$CN: landing-portrait-section;

.#{$CN} {
	display: flex;
	flex-direction: column;
	gap: 30px;
}
