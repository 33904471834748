.ExpressLessons {
	height: 100%;
	overflow-y: auto;
	padding: 40px 10px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 24px;

	@media only screen and (min-device-width: 768px) {
		padding: 40px;
	}

	&__Control {
		display: flex;
		justify-content: space-between;
	}
}
