@import "../../../../app/App/css/variables";

$CN: students-stats;

.#{$CN} {
	display: flex;
	flex-direction: row;
	gap: 24px;
	overflow: auto;
}

@media (min-width: 600px) {
	.#{$CN} {

	}
}
