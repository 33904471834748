@import "../../../../../../app/App/css/variables";
@import "../../../../common/landing-common";

$CN: land-const-faq-aside;

.#{$CN} {

}
@include media-monitor() {
	.#{$CN} {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		padding: 40px 40px 0 40px;
		border: 1px solid #D5D7DC;
		border-radius: 20px;
	}
}

.#{$CN}__header {
	font-size: 30px;
	font-weight: 700;
	line-height: 32px;
	letter-spacing: -1px;

	@include allThemeGradientClassesForText();
}
@include media-monitor() {
	.#{$CN}__header {
		font-size: 36px;
		line-height: 44px;
	}
}

.#{$CN}__img-w {
	display: none;
}
@include media-monitor() {
	.#{$CN}__img-w {
		display: block;
	}
}