@import "../../../../../../app/App/css/variables";
@import "../../../../common/landing-common";
@import "../common/common";

$CN: tariff-card-theses;

.#{$CN} {
	display: grid;
	gap: 4px;
	font-size: 18px;
	font-weight: 500;
	line-height: 26px;
	color: #5E5A72;
	margin: 15px 0 0 0;

	&--white {
		color: white;
	}
}

.#{$CN}__thesis {
	position: relative;
	padding: 0 0 0 20px;

	&:hover {
		.#{$CN}__thesis-button {
			display: block;
		}
	}
}

.#{$CN}__thesis-button {
	position: absolute;
	display: none;

	&--delete {
		top: -11px;
		right: -11px;
		color: $colorError;

		&:hover {
			color: $colorError !important;
			border-color: $colorError !important;
		}
	}

	&--strike {
		top: -11px;
		right: 20px;
	}

	&--strike.ant-btn-default {
		color: $colorPrimary;
	}

	&--add {
		right: calc(50% - 11px);
		bottom: -11px;
		color: $colorPrimary;
	}
}

.#{$CN}__thesis-text {
	margin: 0;

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 0;
		width: 10px;
		height: 10px;
		transform: translateY(-3px) rotate(45deg);
	}

	@include allThemeColorClassesForBefore();

	&--cross-out span {
		color: $colorTextTertiary;
		text-decoration: line-through;
	}
}

.#{$CN}--white {
	.#{$CN}__thesis-text--cross-out span {
		color: white;
	}
}
