$CN: 'exercise-image';

.#{$CN} {
	position: relative;
	overflow: hidden;
	padding: 100% 0 0 0;
	border-radius: 2px;
}

.#{$CN}__inner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.#{$CN}__inner .ant-image {
	//flex-shrink: 0;
	width: 100%;
	height: 100%;

	.ant-image-img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}