@import "../../App/css/variables";

$CN: school-name;

.#{$CN} {
	cursor: pointer;
	padding: 12px 6px 12px 24px;
	margin: 0 4px;
	border-radius: 8px;

	&:hover {
		background-color: $colorSplit;
	}
}

.#{$CN}__top {
	font-size: 12px;
	line-height: 20px;
	color: $colorTextTertiary;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	margin: 0 0 2px 0;
}

.#{$CN}__name-wrapper {
	display: flex;
	align-items: center;

	.anticon {
		display: block !important;
		margin: -1px 6px 0 0;
	}
}

.#{$CN}__name {
	display: block;
	font-size: 14px;
	line-height: 22px;
	font-weight: 600;
	color: $colorTextHeading;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	margin: 0;
}
