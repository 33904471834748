@import "../../../../../app/App/css/variables";

$CN: visit-training;

.#{$CN} {

}

.#{$CN}__header {
	@include Heading__Heading-4;
}

.#{$CN}__cards {
	margin: 24px 0 0 0;
}
