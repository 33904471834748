@import "../../App/css/variables";

$CN: navbar;

.#{$CN} {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 24px;
}

.#{$CN}__logo {
	display: none;

	padding: 5px 12px;
	border-radius: 4px;
	margin-left: -12px;
	transition: background-color .2s;

	&:hover {
		background-color: $colorSplit;
	}
}
@media (min-width: 500px) {
	.#{$CN}__logo {
		display: block;
	}
}

.#{$CN}__right {
	display: flex;
	align-items: center;
	margin-left: auto;
}

.#{$CN}__badge {
	cursor: pointer;
	transform: translateY(3px);
	margin-right: 30px;

	&:hover {
		.#{$CN}__icon {
			color: rgb(50, 50, 50);
		}
	}
}
.#{$CN}__icon {
	color: $colorIcon;
	cursor: pointer;

	svg {
		width: 22px;
		height: 22px;
	}
}
