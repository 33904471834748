@import "../../../../../../app/App/css/variables";
@import "../../../../common/landing-common";

$CN: landing-faq-section;

.#{$CN} {
	display: grid;
	gap: 10px;
	align-items: start;
}
@include media-monitor() {
	.#{$CN} {
		grid-template-columns: 360px 1fr;
		gap: 30px;
	}
}
