@import "../App/css/variables";

$CN: main-part;

.#{$CN} {
    position: relative;
    flex-grow: 1;
    display: flex;
}

.#{$CN}__aside {
	display: none;
}
@media (min-width: 500px) {
	.#{$CN}__aside {
		display: flex;
	}
}

.#{$CN}__article {
    flex-grow: 1;
    display: flex;
    overflow: auto;
	width: 100%;
    height: calc(100vh - 68px);
	background-color: $colorBgLayout;
	border-radius: 8px 0 0 0;
}
