$CN: preview-exercise;

.#{$CN} {

}
@media (min-width: 1100px) {
	.#{$CN} {
		display: flex;
		flex-flow: row-reverse;
		align-items: flex-start;
		width: 100%;
	}
}

// ПОТОМ УДАЛИТЬ !!!
/*.#{$CN}__timer-wrapper {
	text-align: right;
	margin: 0 0 12px 0;
}*/
/*.#{$CN}__timer {
	display: inline-block;
	background-color: #f6f6f6;
	padding: 2px 10px;
	margin: 0;
	border-radius: 10px;
}*/

.#{$CN}__aside {
	min-height: 10px;
	background-color: white;
	overflow: hidden;
	padding: 24px 24px 0 24px;
	margin: 0 0 20px 0;
	border-bottom: 1px solid #f0f2f5;
	border-radius: 4px;
}
@media (min-width: 1100px) {
	.#{$CN}__aside {
		width: 250px;
		min-width: 250px;
		background-color: transparent;
		padding: 0;
		margin: 0 0 0 20px;
		border-bottom: none;
		border-left: 1px solid #f0f2f5;
	}
}

.#{$CN}__content {
	flex-grow: 1;
}
