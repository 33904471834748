$CN: confirm-email-page-content;

.#{$CN} {
}

.#{$CN}__bottom-form-item {
	width: 100%;
}

.#{$CN}__input {
	font-size: 38px;
	font-weight: 600;
	text-align: center;
}

.#{$CN}__email-warning {
	color: gray;
	text-align: center;
	margin: 30px 0 10px 0;
}