@import "../../../../app/App/css/variables";
$CN: drawer-preset;

.#{$CN} {
    width: 100%;
    border-color: $colorBorder;
    border-radius: 12px;

    .ant-card-body {
        padding: 0
    }
}

.#{$CN}__icon {
    width: 85px;
    height: 85px;
    padding: 8px;
    span, svg {
        width: 100%;
        height: 100%;
    }
}

.#{$CN}__content {
    width: auto;
    flex: 1 1 auto;
}

.#{$CN}__name {
    @include Base__Base-Strong();
    color: $colorTextHeading;
}

.#{$CN}__description {
    @include SM__SM-Normal();
    color: $colorTextSecondary;
}

.#{$CN}__delete-wrapper {
    align-self: center;
}

.#{$CN}__delete {
    @include Base__Base-Normal();
    color: $colorErrorBase;
}

.#{$CN}__delete:hover {
    color: $colorTextHeading;
}