$gradient-1: linear-gradient(90deg, rgba(178, 87, 249, 1), rgba(129, 87, 249, 1));
$gradient-2: linear-gradient(90deg, rgba(221, 170, 119, 1), rgba(207, 144, 84, 1));
$gradient-3: linear-gradient(90deg, rgba(64, 188, 227, 1), rgba(89, 132, 217, 1));
$gradient-4: linear-gradient(90deg, rgba(27, 192, 113, 1), rgba(11, 182, 49, 1));

@mixin allThemeGradientClasses() {
	&--colorScheme_1 {
		background: $gradient-1;
	}

	&--colorScheme_2 {
		background: $gradient-2;
	}

	&--colorScheme_3 {
		background: $gradient-3;
	}

	&--colorScheme_4 {
		background: $gradient-4;
	}
}

@mixin cropGradientInText {
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

@mixin allThemeGradientClassesForText() {
	&--colorScheme_1 {
		background: $gradient-1;
		@include cropGradientInText;
	}

	&--colorScheme_2 {
		background: $gradient-2;
		@include cropGradientInText;
	}

	&--colorScheme_3 {
		background: $gradient-3;
		@include cropGradientInText;
	}

	&--colorScheme_4 {
		background: $gradient-4;
		@include cropGradientInText;
	}
}

@mixin allThemeColorClasses() {
	&--colorScheme_1 {
		background: rgba(255, 183, 32, 1);
	}

	&--colorScheme_2 {
		background: rgba(82, 146, 241, 1);
	}

	&--colorScheme_3 {
		background: rgba(239, 89, 56, 1);
	}

	&--colorScheme_4 {
		background: rgba(158, 98, 255, 1);
	}
}

@mixin allThemeColorClassesForText() {
	&--colorScheme_1 {
		background: rgba(255, 183, 32, 1);
		@include cropGradientInText;
	}

	&--colorScheme_2 {
		background: rgba(82, 146, 241, 1);
		@include cropGradientInText;
	}

	&--colorScheme_3 {
		background: rgba(239, 89, 56, 1);
		@include cropGradientInText;
	}

	&--colorScheme_4 {
		background: rgba(158, 98, 255, 1);
		@include cropGradientInText;
	}
}

@mixin allThemeGradientClassesForBefore() {
	&--colorScheme_1::before {
		background: $gradient-1;
	}

	&--colorScheme_2::before {
		background: $gradient-2;
	}

	&--colorScheme_3::before {
		background: $gradient-3;
	}

	&--colorScheme_4::before {
		background: $gradient-4;
	}
}

@mixin allThemeColorClassesForBefore() {
	&--colorScheme_1::before {
		background: rgba(255, 183, 32, 1);
	}

	&--colorScheme_2::before {
		background: rgba(82, 146, 241, 1);
	}

	&--colorScheme_3::before {
		background: rgba(239, 89, 56, 1);
	}

	&--colorScheme_4::before {
		background: rgba(158, 98, 255, 1);
	}
}

@mixin media-monitor {
	@media (min-width: 1000px) {
		@content;
	}
}
