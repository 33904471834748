.StorageItem {
	width: 100%;
	// height: 84px;
  padding: 12px 10px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
  gap: 10px;
  border-bottom: 1px solid var(--Placeholder, rgba(196, 196, 208, 0.20));

  &__ItemChecked {
    background-color: #DAE9FF;
  }

	&__CheckBox {
	}

	&__Content {
    flex: 2 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
	}

  &__Name {
    display: flex;
    gap: 6px;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.4px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__FileName {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__SizeType {
    display: flex;
    gap: 6px;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px; 
    letter-spacing: -0.4px;
  }

  &__UploadDate {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.4px;
  }

	&__Actions {
    align-self: center;
    transform: rotate(90deg);
	}
}
