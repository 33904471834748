$CN: 'exercise-audio-player';

.#{$CN} {
	background-color: white;
	padding: 15px 15px;
	border: 1px solid #D9D9D9;
	border-radius: 6px;
}
@media (min-width: 650px) {
	.#{$CN} {
		display: flex;
		justify-content: space-between;
		padding: 10px 24px;
	}
}

.#{$CN}__left {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.#{$CN}__right {
	margin: 20px 0 0 0;
}
@media (min-width: 650px) {
	.#{$CN}__right {
		display: flex;
		align-items: center;
		margin: 0 0 0 0;
	}
}

// КРУГ ПРИ ЗАПИСИ
.#{$CN}__rec-circle {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
}
.#{$CN}__rec-pic-circle {
	z-index: 1;
}
.#{$CN}__rec-flame-circle {
	position: absolute;
	z-index: 0;
	width: 10px;
	height: 10px;
	background-color: rgba(239, 85, 51, .2);
	border-radius: 50%;
	animation: flame 3s infinite;
}
.#{$CN}__rec-flame-circle--delay {
	animation-duration: 2s;
}
@keyframes flame {
	0% {
		transform: scale(2.65);
	}
	50% {
		transform: scale(4.5);
	}
	100% {
		transform: scale(2.65);
	}
}

// КРУГ ПРИ ПАУЗЕ И ВОСПРОИЗВЕДЕНИИ
.#{$CN}__action-circle {
	cursor: pointer;
	background-color: rgba(29, 171, 242, 0);
	padding: 0;
	border: none;
	border-radius: 50%;
	transition: background-color .3s;

	&:hover {
		background-color: rgba(29, 171, 242, .2);
	}
}

// ЗВУКОВАЯ ВОЛНА
.#{$CN}__wave {
	width: 120px;
	margin: 0 auto 0 12px;
}
.#{$CN}__wave--pale {
	opacity: .5;
}
@media (min-width: 400px) {
	.#{$CN}__wave {
		width: auto;
		margin: 0 auto 0 18px;
	}
}
@media (min-width: 650px) {
	.#{$CN}__wave {
		margin-right: 15px;
	}
}

// ТАЙМЕР
.#{$CN}__timer {
	font-size: 16px;
	line-height: 22px;
	color: #979798;
	margin: 0;
}

// ПРАВАЯ ЧАСТЬ
.#{$CN}__record-button {
	width: 100%;
}
@media (min-width: 650px) {
	.#{$CN}__record-button {
		width: auto;
	}
}

.#{$CN}__divider-wrapper {
	display: flex;
	align-items: center;
}
.#{$CN}__divider {
	width: 1px;
	height: 32px;
	background-color: #D9D9D9;
	margin: 0 20px;
}

.#{$CN}__record-text {
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
	color: #EF5533;
	margin: 0 auto 0 0;
}
@media (min-width: 650px) {
	.#{$CN}__record-text {
		margin: 0;
	}
}

.#{$CN}__delete {
	border-color: #979798;
	margin: 0 0 0 auto;

	* {
		fill: #979798;
	}

	&:hover {
		border-color: #FF8987;

		* {
			fill: #FF8987;
		}
	}

	&:active {
		background-color: #FF8987;

		* {
			fill: white;
		}
	}
}
@media (min-width: 650px) {
	.#{$CN}__delete {
		margin: 0;
	}
}