@import "../../../../../app/App/css/variables";
@import "../../../common/landing-common";

$CN: land-const-video-section;

.#{$CN} {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 20px;
}
@include media-monitor() {
	.#{$CN} {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
}

.#{$CN}__top-part {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12px;
	overflow: hidden;
	padding-bottom: 4px;
}
@include media-monitor() {
	.#{$CN}__top-part {
		order: 1;
		justify-content: space-between;
		min-height: 385px;
		max-height: 500px;
		padding: 24px 40px;
	}
}

.#{$CN}__description {
	font-size: 20px;
	font-weight: 500;
	line-height: 28px;
	letter-spacing: -0.4px;
	margin: 0;
}

.#{$CN}__bottom-part {
	& > * {
		flex-grow: 1;
	}
}
@include media-monitor() {
	.#{$CN}__bottom-part {
		display: flex;
		align-items: center;
	}
}

.#{$CN}__iframe {
	overflow: hidden;
	border-radius: 16px;
}

