@import "../../../../../app/App/css/variables";
@import "../../../common/landing-common";

$CN: land-const-footer;

.#{$CN} {
	font-size: 16px;
	font-weight: 500;
	line-height: 26px;
	color: #5E5A72;
	text-align: right;
	background-color: #F4F5F7;
	padding: 30px 20px;
	margin: 60px 0 0 0;
}
@include media-monitor() {
	.#{$CN} {
		padding: 30px 40px;
	}
}