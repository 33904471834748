@import 'app/App/css/variables';

.UploadProgress {
	margin-left: 40px;
	position: fixed;
	bottom: 25px;
	float: left;
	width: 280px;
	height: 62px;
	background: #ffffff;
	box-shadow: 0px 2px 4px 0px rgba(34, 52, 71, 0.03),
		0px 1px 6px -1px rgba(34, 52, 71, 0.03),
		0px 0px 1px 1px rgba(34, 52, 71, 0.05);
	border-radius: 8px;
	padding: 10px;
}

.UploadProgress__Header {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.Header__FileName {
	flex: 1 1 70%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	gap: 6px;
}

.UploadProgress__Text {
	font-size: 14px;
	font-weight: 500;
	line-height: 14px;
	text-overflow: ellipsis;
	color: var(--dark);
}

.UploadProgress__Close {
	cursor: pointer;

	path {
		stroke: #acb4c0;
	}
}

.UploadProgress__Content {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 6px;
	align-items: center;

	& progress::-webkit-progress-value {
		background: var(--primary);
	}
	& progress::-webkit-progress-bar {
		background: #c4c4c4;
	}
}

.UploadProgress__Progress {
	width: 219px;
	height: 3px;
	border-radius: 10px;
}

.UploadProgress__ProgressValue {
	font-size: 12px;
	font-weight: 400;
	line-height: 18px; /* 150% */
	letter-spacing: -0.4px;
}
