@import "../../../../../../app/App/css/variables";
@import "../../../../common/landing-common";

$CN: p-tariff-card-theses;

.#{$CN} {
	display: grid;
	gap: 12px;
	font-size: 18px;
	line-height: 26px;
	font-weight: 500;
	color: #5E5A72;
	margin: 24px 0 0 0;
}

.#{$CN}__thesis {

}

.#{$CN}__thesis-text {
	position: relative;
	padding: 0 0 0 20px;
	margin: 0;

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 0;
		width: 10px;
		height: 10px;
		transform: translateY(-3px) rotate(45deg);
	}

	&--default {
		@include allThemeColorClassesForBefore();
	}

	&--flashed {
		@include allThemeGradientClassesForBefore();
	}

	&--cross-out {
		color: $colorTextTertiary;
		text-decoration: line-through;
	}
}
