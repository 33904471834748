@import "../../../../../app/App/css/variables";
@import "../../../common/landing-common";

$CN: land-const-main-section;

.#{$CN} {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100vh;
	max-height: 800px;
	overflow: hidden;

	@include allThemeGradientClasses();
}
@include media-monitor() {
	.#{$CN} {
		display: grid;
		grid-template-columns: 1fr 1fr;
		border-radius: 40px;
	}
}


.#{$CN}__left {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 20px;
	padding: 30px 20px;
}
@include media-monitor() {
	.#{$CN}__left {
		justify-content: center;
		gap: 30px;
		padding: 60px 60px;
	}
}

.#{$CN}__right {
	overflow: hidden;
}
@include media-monitor() {
	.#{$CN}__right {
		display: flex;
		align-items: flex-end;
	}
}

.#{$CN}__top-header-wrapper {
	background-color: white;
	padding: 6px 16px;
	border-radius: 100px;
}
@include media-monitor() {
	.#{$CN}__top-header-wrapper {
		display: inline-block;
	}
}

.#{$CN}__top-header {
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	font-weight: 600;
	margin: 0;

	@include allThemeGradientClassesForText();
}
@include media-monitor() {
	.#{$CN}__top-header {
		display: inline-block;
		font-size: 22px;
		line-height: 30px;
	}
}

.#{$CN}__header {
	font-size: 40px;
	line-height: 46px;
	color: white;
	font-weight: 800;
	margin: 0;
}
@include media-monitor() {
	.#{$CN}__header {
		font-size: 72px;
		line-height: 80px;
	}
}

.#{$CN}__description {
	font-size: 20px;
	line-height: 28px;
	color: white;
	font-weight: 500;
	margin: 0;
}