$CN: 'exercise-collapse';

.#{$CN}__text {
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  cursor: pointer;
	user-select: none;
  margin: 30px 0 8px 0;
}

.#{$CN}__triangle {
  transform: rotate(-90deg);
  transition: transform .1s;
  margin-left: 5px;
}
.#{$CN}__triangle--open {
  transform: rotate(0deg);
}
