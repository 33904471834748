@import "variables";

:root {
	font-size: 16px;
}

body {
	font-family: 'Montserrat', sans-serif;
	// font-size: 1.4rem;
	line-height: 1.8rem;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#root {
	display: flex;
	min-height: 100vh;
	box-sizing: border-box;
	align-items: center;
}

.app {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-height: 100vh;
	color: var(--text-color);
	overflow: auto;
}

a {
	text-decoration: none;
}

.link {
	color: rgb(22, 119, 255);
	text-decoration: none;
	border-bottom: 1px solid rgba(22, 119, 255, .25);
	transition: border-bottom .2s;

	&:hover {
		border-bottom: 1px solid rgba(22, 119, 255, .4);
	}
}

.bold-500 {
	font-weight: 500;
}
.bold-600 {
	font-weight: 600;
}
.error-color {
	color: $colorError;
}


.ant-form, .ant-input, .ant-col, .ant-modal, .ant-drawer, .ant-dropdown, .ant-btn, .ant-alert, .ant-collapse {
	font-family: 'Montserrat', sans-serif;
}