@import "../../../app/App/css/variables";

$CN: 'upload-avatar';

.#{$CN} {
	width: 120px;
	height: 120px;

	.ant-upload-list-item-image {
		object-fit: cover!important;
	}

	.ant-upload {
		margin: 0 !important;
	}

	.ant-upload.ant-upload-select {
		width: 120px !important;
		height: 120px !important;
		background-color: $controlItemBgActive !important;
	}

	.ant-upload-list-item-container {
		width: 120px !important;
		height: 120px !important;
	}

	.ant-upload-list-item-container {
		transition: height 0s !important;
	}
}

.#{$CN}__add {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: $colorLink;
	transform: translateY(4px);
}

.#{$CN}__add-sign {
	font-size: 28px;
}

.#{$CN}__add-text {
	font-size: 14px;
	margin: 0;
}