/* БЛОК ЗАКАЧКИ */
$CN-uploading: 'exercise-uploading-block';

.#{$CN-uploading} {
  display: flex;
  padding: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.#{$CN-uploading}__left {
  flex-shrink: 0;
  padding: 0 11px 0 0;
}
.#{$CN-uploading}__center {
  flex-grow: 1;
}
.#{$CN-uploading}__right {
  flex-shrink: 0;
  padding: 0 0 0 5px;
}
.#{$CN-uploading}__circle {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EBEFF7;
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.#{$CN-uploading}__block {
  width: 36px;
  height: 36px;
  border-radius: 1px;
  overflow: hidden;
}
.#{$CN-uploading}__pic {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.#{$CN-uploading}__center-top {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}
.#{$CN-uploading}__file-name {
  color: #87898E;
}
.#{$CN-uploading}__text {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: #87898E;
  margin: 0 0 5px 0;
}
.#{$CN-uploading}__progress {
  position: relative;
  height: 5px;
  background-color: #DFDFE6;
  overflow: hidden;
  border-radius: 2px;
}
.#{$CN-uploading}__progress-bar {
  height: 100%;
  background-color: #1DABF2;
}

/* БЛОК УДАЧНОЙ ЗАКАЧКИ ВИДЕО */
$CN-upload-success: 'exercise-success-upload-block';

.#{$CN-upload-success} {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.#{$CN-upload-success}__image {
  max-width: 50px;
  max-height: 50px;
  border-radius: 2px;
  margin: 0 10px 0 0;
}
.#{$CN-upload-success}__text {
  flex-grow: 1;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: #87898E;
  margin: 0;
}
.#{$CN-upload-success}__close {
  flex-shrink: 0;
}

/* ПОЛЕ ЗАГРУЗКИ ФАЙЛА В ВИДЕ ОБЛАСТИ ПЕРЕТАСКИВАНИЯ ФАЙЛА */
$CN-uploading-dragger: 'exercise-dragger-uploading';

.#{$CN-uploading-dragger} {
  background: white !important;
  border-radius: 6px !important;
  border-width: 2px !important;
  border-color: #E2E6EA;

  .ant-upload-hint {
    width: 50% !important;
    margin: 0 auto;
  }

  .ant-upload-hint--medium {
    padding: 15px 0 !important;
  }
}