@import "../../../../../../app/App/css/variables";
@import "../../../../common/landing-common";

$CN: landing-faq-block;

.#{$CN} {
	display: flex;
	gap: 16px;
	background: #F4F5F7;
	padding: 20px 16px;
	border-radius: 20px;
}
@include media-monitor() {
	.#{$CN} {
		padding: 24px 30px;
	}
}

.#{$CN}__text-part {
	flex-grow: 1;
}

.#{$CN}__question, .#{$CN}__answer {
	margin: 0;
}

.#{$CN}__question {
	color: #2C2A35;
	font-size: 18px;
	font-weight: 600;
	line-height: 20px;
	white-space: pre-wrap;
}
@include media-monitor() {
	.#{$CN}__question {
		font-size: 22px;
		line-height: 30px;
	}
}

.#{$CN}__question + .#{$CN}__answer {
	margin-top: 12px;
}

.#{$CN}__answer {
	color: #5E5A72;
	font-size: 18px;
	font-weight: 500;
	line-height: 26px;
	white-space: pre-wrap;
}
