$CN: error-page;

.#{$CN} {
	align-self: center;
}

.#{$CN}__illustration {
	width: 100%;
	max-width: 438px;
}

.#{$CN}__content {
	margin: 40px 0 0 0;
}

.#{$CN}__header {
	font-size: 30px;
	line-height: 38px;
	font-weight: 600;
	margin: 0;
}

.#{$CN}__text {
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
	margin: 10px 0 0 0;
}

.#{$CN}__error-text {
	font-size: 14px;
	line-height: 18px;
	font-weight: 500;
	color: white;
	background-color: #393d65;
	border-radius: 2px;
	padding: 5px 10px;
	margin: 16px 0 0 0;
}

.#{$CN}__button-wrapper {
	display: flex;
	justify-content: center;
	margin: 16px 0 0 0;
}

@media (min-width: 400px) {
	.#{$CN} {
		text-align: center;
	}
}

@media (min-width: 1000px) {
	.#{$CN} {
		display: flex;
		align-items: center;
		gap: 20px;
		text-align: left;
	}

	.#{$CN}__content {
		margin-top: 0;
	}
}
