@import "../../../../../../app/App/css/variables";

$CN: tariff-card-main-price-wrapper;

.#{$CN} {
	position: relative;
	display: grid;
	gap: 24px;
	margin: 24px 0 0 0;
	border: 1px solid transparent;
	border-radius: 4px;
	transition: border-color .2s;

	&:hover {
		border-color: $colorPrimaryBorder;

		.#{$CN}__link-button {
			display: block;
		}
	}
}

.#{$CN}__link-button {
	position: absolute;
	display: none;
	top: -11px;
	right: -11px;
	color: $colorPrimary;
}
