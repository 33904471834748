.FolderPath {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;

	@media only screen and (max-device-width: 768px) {
		padding-left: 10px;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 20px; /* 125% */
		letter-spacing: -0.4px;
	}
}