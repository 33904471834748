@import "../../../../../../../app/App/css/variables";
@import "../../../../../common/landing-common";

$CN: land-const-editable-text;

.#{$CN} {
	display: inline-block;
	width: 100%;

	&:empty::before {
		content: attr(data-placeholder);
		color: rgba(0, 0, 0, .3);
	}
}
