$CN: 'exercise-tags';

.#{$CN}__plus {
	background: #fff;
	border-style: dashed;
}
.#{$CN}__tag {
	user-select: none;
}
.#{$CN}__input {
	width: 100px;
	margin-right: 8px;
	vertical-align: top;
}
