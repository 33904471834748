$CN: 'exercise-desc-image-content';

.#{$CN}__exercise-wrapper {
	position: relative;
	flex-direction: column;
}

.#{$CN}__words {
	position: sticky;
	top: 0;
	z-index: 1;
	max-height: 30vh;
	background-color: white;
	overflow: auto;
	padding: 0 0 8px 8px;
	margin: 15px 0 15px 0;
	border: 2px dashed #EBEFF2;
	border-radius: 4px;
	transition: background-color .3s;

	&--shadow {
		background-color: #EBEFF2;
	}
}
@media (min-width: 500px) {
	.#{$CN}__words {
		margin: 30px 0 30px 0;
	}
}

.#{$CN}__word {
	font-size: 14px;
	line-height: 18px;
	font-weight: 500;
	color: white;
	text-align: left;
	background-color: #00ADF9;
	cursor: grab;
	padding: 4px 8px;
	border: none;
	border-radius: 3px;
	transition: background-color .3s, color .3s;

	&--inside {
		margin: 10px 10px 0 0;
	}

	&--full-wide {
		width: 100%;
	}

	&--error {
		color: #1F1F1F;
		background-color: #FFF1F0;
		border: 1px solid #FFC0C0;
	}
	&--success {
		color: #1F1F1F;
		background-color: #F6FFED;
		border: 1px solid #8DE748;
	}
	&--ghost {
		color: rgb(140, 140, 140);
		background-color: white;
		cursor: default;
	}
}

.#{$CN}__images {
	flex-shrink: 2;
	display: grid;
	gap: 20px;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.#{$CN}__image-wrapper {
	position: relative;
	border-radius: 2px;
}

.#{$CN}__close-btn {
	position: absolute;
	top: 5px;
	right: 5px;
	z-index: 3;
	background: white;
	border: none;

	&:hover {
		background: white;
	}
}

.#{$CN}__slot {
	min-height: 28px;
	//background-color: #F6F8FA;
	margin-top: 15px;
	border: 1px dashed #c7ccd5;
	border-radius: 4px;
	transition: background-color .3s;

	&--error {
		background-color: #FFF1F0;
	}
	&--shadow {
		background-color: #EBEFF2;
	}
}

.#{$CN}__right-answer {
	display: none;
	font-weight: 600;
	color: white;
	background-color: #72CC19;
	padding: 7px 12px;
	margin-top: 10px;
	border-radius: 4px;

	&--open {
		display: block;
	}
}

.#{$CN}__bottom {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 40px 0 0 0;
}

.#{$CN}__bottom-attempts {
	margin: 0;
}

.#{$CN}__bottom-open-gaps {
	display: none;
	margin: 0 15px 0 0;
}
@media (min-width: 500px) {
	.#{$CN}__bottom-open-gaps {
		display: inline-block;
	}
}
