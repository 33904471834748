@import "../../../app/App/css/variables";

$CN: 'upload-image';

.#{$CN} {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	&:hover {
		.#{$CN}__upload-sign {
			display: block;
		}
	}
}

.#{$CN}__input {
	display: none;
}

.#{$CN}__upload-sign {
	position: absolute;
	z-index: 1;
	display: none;
}

.#{$CN}__image {
	object-fit: cover;
	width: 100%;
	height: 100%;
}
