@import '../../../../app/App/css/variables.scss';
@import '../../../../app/App/css/adaptive.scss';

.CourseCard {
	// max-width: 572px;

	border-radius: 20px;
	background: var(--colorBorderBg, #fff);
	/* boxShadow */
	box-shadow: 0px 2px 4px 0px rgba(34, 52, 71, 0.03),
		0px 1px 6px -1px rgba(34, 52, 71, 0.03),
		0px 0px 1px 1px rgba(34, 52, 71, 0.05);

	@media only screen and (min-device-width: 768px) {
		flex: 1 1 200px;
	}

	&__Image {
		width: auto;
		display: flex;
		justify-content: center;
		background: linear-gradient(
			160deg,
			rgba(211, 226, 248, 0.63) -17.09%,
			rgba(228, 236, 248, 0) 70.31%
		);
		border-top-right-radius: 20px;
		border-top-left-radius: 20px;

		& img {
			max-height: 140px;
			object-fit: cover;

			@media only screen and (min-device-width: 768px) {
				width: 100%;
				max-height: 292px;
			}
		}
	}

	&__Content {
		height: auto;
		padding: 20px;
		display: flex;
		flex-direction: column;
		gap: 10px;

		@media only screen and (min-device-width: 768px) {
			justify-content: space-between;
		}
	}

	&__ContentText {
		display: flex;
		flex-direction: column;
		gap: 6px;
	}

	&__ContentCount {
		@include Base__Base-Normal;
		overflow: hidden;
		color: var(--colorTextSecondary, rgba(5, 25, 46, 0.65));
	}

	&__ContentTitle {
		@include Heading__Heading-4;
	}

	&__ContentSubTitle {
		height: 66px;
		overflow: hidden;
		@include Base__Base-Normal;
		display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
	}

	&__ContentButton {
		display: flex;
		justify-content: center;
		align-items: center;

		@media only screen and (min-device-width: 768px) {
			align-self: flex-start;
		}
	}
}
