@import "../../App/css/variables";

$CN: user-menu-top;

.#{$CN} {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 65px;
	cursor: pointer;
	margin: 0 0 0 6px;
}
