$CN: 'exercise-test';

/* ОСНОВНЫЕ ЧАСТИ */
.#{$CN}__label {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;

  & + * {
    margin-top: 8px;
  }
}

.#{$CN}__label--top-margin {
  display: block;
  margin-top: 16px;
}

.#{$CN}__input-error {
  font-size: 14px;
  line-height: 20px;
  color: #EC5B57;
  margin: 5px 0 0 0;
}

/* ФЛАГИ КОЛИЧЕСТВА ВАРИАНТОВ ОТВЕТОВ */
$CN-answers: 'exercise-test-answers-count';

.#{exercise-test-answers-count} {
  display: flex;
  margin: 16px 0 0 0;
}

.#{exercise-test-answers-count}__label {
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
}

/* БЛОК ВОПРОСОВ */
$CN-questions: 'exercise-test-questions';

.#{$CN-questions}__header {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  margin: 20px 0 10px 0;
}

/* БЛОК ВОПРОСА */
$CN-question: 'exercise-test-question';

.#{$CN-question} {
  display: flex;
  padding: 0 0 16px 0;
  border-bottom: 1px solid rgb(30, 30, 30);
}
.#{$CN-question} + .#{$CN-question} {
  margin-top: 20px;
}

.#{$CN-question}__content {
  flex-grow: 1;
}

.#{$CN-question}__remove-button {
  margin: 29px 0 0 16px;
}

.#{$CN-question}__tabs-input {
  display: block;
  margin-top: 16px;
}

.#{$CN-question}__tabs {
  .ant-tabs-tab {
    padding: 4px 12px !important;
  }
  .ant-tabs-tab .anticon {
    margin-right: 0;
  }
}

/* БЛОК ССЫЛКИ НА ПОЛЕ СО ССЫЛКОЙ НА ВНЕШНЕЕ ВИДЕО */
$CN-video-link-input: 'exercise-test-video-link-input';

.#{$CN-video-link-input} {
  margin: 0 0 10px 0;
}

/* БЛОК ПРОВАЛЕННОЙ ЗАГРУЗКЕ ВИДЕО */
$CN-video-error-upload: 'exercise-test-video-error-upload';

.#{$CN-video-error-upload} {

}

.#{$CN-video-error-upload}__text {

}

/* БЛОК ПРОВАЛЕННОЙ ЗАКАЧКИ МЕДИА-ФАЙЛА (картинки или аудио) */
$CN-media-upload-error: 'exercise-test-media-upload-error';

.#{$CN-media-upload-error}__text {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: #87898E;
  margin: 0 0 0 0;
}

/* БЛОК ОТВЕТОВ */
$CN-answers: 'exercise-test-answers';

.#{$CN-answers}__items {
  display: grid;
  gap: 16px;
}

.#{$CN-answers}__add-button {
  margin: 16px 0 0 20px;
}

/* БЛОК ОТВЕТА */
$CN-answer: 'exercise-test-answer';

.#{$CN-answer} {
  display: flex;
  align-items: center;
}

.#{$CN-answer}__letter {
  display: inline-block;
  width: 26px;
}
.#{$CN-answer}__checkbox {
  margin: 0 0 0 10px;
}

.#{$CN-answer}__remove-button {
  margin: 0 0 0 10px;
}

/* КНОПКА ДОБАВЛЕНИЯ НОВОГО ВОПРОСА */
$CN-add-question-button: 'exercise-test-add-question-button';

.#{$CN-add-question-button} {
  margin: 16px 0 0 0;
}
