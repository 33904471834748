@import "../../../../../app/App/css/variables";
@import "../../../common/landing-common";

$CN: land-const-color-scheme-modal;

.#{$CN}__radio {
	display: flex;
	align-items: center;
	transform: translateY(3px) translateX(-2px);
}

.#{$CN}__radio-circles {
	display: flex;
	margin: 0 8px 0 0;
}

.#{$CN}__radio-circle {
	width: 26px;
	height: 26px;
	background-color: #1DABF2;
	border: 2px solid $colorBgLayout;
	border-radius: 50%;
}

.#{$CN}__radio-circle-left {
	@include allThemeGradientClasses();
}

.#{$CN}__radio-circle-right {
	margin-left: -8px;
	@include allThemeColorClasses();
}

.#{$CN}__radio-text {
	margin: 0;
}