@import "../../../../../../app/App/css/variables";
@import "../../../../common/landing-common";

$CN: p-tariff-card-buy-btn;

.#{$CN} {
	width: 100%;
	font-size: 20px;
	line-height: 28px;
	font-weight: 700;
	text-align: center;
	text-transform: uppercase;
	color: white;
	cursor: pointer;
	padding: 12px 16px;
	margin: 24px 0 0 0;
	border-radius: 16px;
	border: none;

	@include allThemeGradientClasses();}
