@import "../../../../app/App/css/variables";

$CN: auth-page;

.#{$CN} {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	gap: 15px;
	height: 100vh;
	background: linear-gradient(135deg, #0958D9, #003EA4);
	//background: #0958D9;
}

@media (min-width: 800px) {
	.#{$CN} {
		flex-direction: row;
	}
}

@media (min-width: 800px) {
	.#{$CN}__left, .#{$CN}__right {
		width: 50%;
	}
}

.#{$CN}__left  {
	display: none;
}

.#{$CN}__slogan {
	@include Heading__Heading-4;
	color: white;
	font-weight: 600;
	text-align: center;
}

.#{$CN}__promo-wrapper  {
	display: flex;
	justify-content: center;
}

.#{$CN}__promo-gradient  {
	max-width: 100%;
	border-radius: 20px;
}
.#{$CN}__promo  {
	max-width: 100%;
	border-radius: 16px;
}

@media (min-width: 800px) {
	.#{$CN}__left  {
		display: flex;
		flex-direction: column;
		padding: 30px;
	}
	.#{$CN}__promo-wrapper  {
		flex-grow: 1;
		align-items: center;
	}
}

.#{$CN}__right  {
	flex-grow: 1;
	display: flex;
}