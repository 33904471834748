@import "../../app/App/css/variables";

$CN: dates-range;

.#{$CN} {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	gap: 4px;
	@include SM__SM-Strong;
	color: $colorTextTertiary;

	&--black {
		color: $colorText;
	}
}

.#{$CN}__date {
	white-space: nowrap;
}

.#{$CN}__flag {
	display: inline-block;
	margin: -2px 3px 0 0;
}
