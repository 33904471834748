$CN: 'exercise-text';

/* ОСНОВНЫЕ ЧАСТИ */
.#{$CN}__label {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;

  & + * {
    margin-top: 10px;
  }
}

.#{$CN}__label--top-margin {
  display: block;
  margin-top: 16px;
}

.#{$CN}__input-error {
  font-size: 14px;
  line-height: 20px;
  color: #EC5B57;
  margin: 5px 0 0 0;
}