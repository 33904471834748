@import "../../../../../app/App/css/variables";

$CN: lesson-card;

.#{$CN} {
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 12px;
}

@media (min-width: 550px) {
	.#{$CN} {
		flex-direction: row;
		padding: 20px;
	}
}

.#{$CN}__cover-wrapper {
	width: 100%;
	height: 134px;
}

@media (min-width: 550px) {
	.#{$CN}__cover-wrapper {
		width: 60px;
		max-width: 60px;
		height: 60px;
	}
}

.#{$CN}__content {
	flex-grow: 1;
}

.#{$CN}__name {
	@include Heading__Heading-5;
	color: $colorTextHeading;
	margin: 0;
}

.#{$CN}__description {
	@include Base__Base-Normal;
	color: $colorTextSecondary;
	margin: 4px 0 0 0;
}
