@import "../../../../../../app/App/css/variables";
@import "../../../../common/landing-common";

$CN: landing-existence-block;

.#{$CN} {
	padding: 20px 20px;
	border-radius: 20px;

	&--green {
		background-color: #E6FFD7;

		.#{$CN}__header {
			background-color: #4ED000;
		}
	}

	&--red {
		background-color: #FFF0F2;

		.#{$CN}__header {
			background-color: #FE2B44;
		}
	}
}

@include media-monitor() {
	.#{$CN} {
		padding: 40px 30px;
	}
}

.#{$CN}__content {
	display: flex;
	flex-direction: column-reverse;
	gap: 10px;
}
@include media-monitor() {
	.#{$CN}__content {
		flex-direction: row;
		align-items: flex-end;
	}
}

.#{$CN}__text-part {
	flex-grow: 1;
}

.#{$CN}__header {
	display: inline-block;
	font-size: 22px;
	font-weight: 600;
	line-height: 30px;
	color: white;
	white-space: pre-wrap;
	padding: 0 10px;
	margin: 0;
	border-radius: 20px;
}

.#{$CN}__header + .#{$CN}__text {
	margin-top: 10px;
}

.#{$CN}__text {
	color: #5E5A72;
	font-size: 18px;
	font-weight: 500;
	line-height: 26px;
	white-space: pre-wrap;
	margin: 0;
}

.#{$CN}__img-part {
	min-width: 64px;
	max-width: 64px;

	min-height: 64px;
	max-height: 64px;
}
.#{$CN}__img-part-img {
	min-width: 64px;
	max-width: 64px;

	min-height: 64px;
	max-height: 64px;
}
