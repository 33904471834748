@import "../../../../../../app/App/css/variables";
@import "../../../../common/landing-common";

$CN: landing-ways-block;

.#{$CN} {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 30px 20px;
	border-radius: 20px;
	overflow: hidden;

	&--wrong {
		border: 1px solid #D5D7DC;

		.#{$CN}__header {
			color: #2C2A35;
		}

		.#{$CN}__text {
			color: #5E5A72;
		}
	}

	&--right {
		.#{$CN}__header {
			color: white;
		}

		.#{$CN}__text {
			color: #D5D7DC;
		}

		@include allThemeGradientClasses();
	}
}
@include media-monitor() {
	.#{$CN} {
		padding: 40px;

		.#{$CN}__btn-wrapper {
			& > * {
				display: inline-block;
				width: auto;
			}
		}

		&--wrong {
			.#{$CN}__btn-wrapper {
				text-align: right;
			}
		}

		&--right {
			.#{$CN}__btn-wrapper {
				text-align: left;
			}
		}
	}
}

.#{$CN}__content {
	display: grid;
	gap: 10px;
}

.#{$CN}__btn-wrapper {
	margin: 30px 0 0 0;

	& > * {
		display: block;
		width: 100%;
	}
}

.#{$CN}__header {
	font-size: 22px;
	font-weight: 600;
	line-height: 30px;
	white-space: pre-wrap;
}

.#{$CN}__text {
	font-size: 18px;
	font-weight: 500;
	line-height: 26px;
	white-space: pre-wrap;
}


.#{$CN}__emoji {
	width: 125px;

	&--sad {
		filter: grayscale(1);
	}
}
@include media-monitor() {
	.#{$CN} {
		position: relative;
	}

	.#{$CN}__emoji {
		position: absolute;
		bottom: -20px;

		&--sad {
			left: 40px;
		}

		&--happy {
			right: 40px;
		}
	}
}

.#{$CN}__do-nothing-btn {
	color: #5E5A72;
	text-align: center;
	font-size: 18px;
	font-weight: 600;
	line-height: 26px;

	background: #F4F5F7;
	box-shadow: 0 -4px 0 0 rgba(0, 0, 0, 0.10) inset;
	padding: 12px 24px;
	border-radius: 16px;
	border: none;
}