.topText {
	color: rgba(5, 26, 46, 0.88);
	text-align: center;
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
}

.personBlock {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	margin: 0 0 16px 0;
}

.personBlockPic {
	width: 40px;
	height: 40px;
}

.personBlockName {
	color: rgba(5, 25, 46, 0.88);
	font-size: 12px;
	line-height: 20px;
	font-weight: 600;
	margin: 0;
}

.personBlockPosition {
	color: rgba(5, 25, 46, 0.88);
	font-size: 12px;
	line-height: 20px;
	font-weight: 500;
	margin: 0;
}