$CN: avatar;

.#{$CN} {
	display: inline-block;
	flex-shrink: 0;
	border-radius: 50%;
	overflow: hidden;
	background-color: #C4C4C4;
}

.#{$CN}__pic {
	flex-shrink: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}