@import "../../app/App/css/variables";

$CN: round-icon-and-text-card;

.#{$CN} {
	flex-grow: 1;
	display: flex !important;
	align-items: center;
	gap: 20px;
	min-width: 250px;
}

.#{$CN}__icon-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 32px;
	line-height: 32px;
	color: $colorPrimaryBase;
	min-width: 80px;
	width: 80px;
	height: 80px;
	background-color: $colorPrimaryBg;
	border-radius: 50%;
}

.#{$CN}__content {
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.#{$CN}__header {
	@include SM__SM-Strong;
	color: $colorPrimary;
	margin: 0;
}

.#{$CN}__num {
	@include Heading__Heading-3;
	color: #292935;
	margin: 0;
}
