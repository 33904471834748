$CN: ui-scrollbar;

.#{$CN} {
    width: 100%;
    height: 100%;
    overflow: scroll;
}

.#{$CN}__content {
    width: 100%;
}
