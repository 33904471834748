@import "../../../../../../app/App/css/variables";
@import "../common/common";
@import "../../../../common/landing-common";

$CN: tariff-card-header;

.#{$CN} {
	font-size: 30px;
	line-height: 1.25em;
	font-weight: 700;
	margin: 0;

	&--white {
		color: white;
	}

	@include allThemeGradientClassesForText();
}

@include media-monitor() {
	.#{$CN} {
		font-size: 36px;
	}
}