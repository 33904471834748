$CN: live-entrance-screen-wrapper;

.#{$CN} {
    text-align: center;
    background-color: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 #22344708, 0 1px 6px -1px #22344708, 0px 0px 1px 1px #2234470D;
}

.#{$CN}__illustration {
    margin: 0 0 20px 0;
}

.#{$CN}__header {
    font-size: 36px;
    line-height: 44px;
    font-weight: 600;
    color: #292935
}

.#{$CN}__description {
    font-size: 16px;
    line-height: 26px;
    color: #4B5265;
    font-weight: 500;
    margin: 16px 0 20px 0;
}
