@import "../../../../../../app/App/css/variables";

$CN: tariff-panel-header;

.#{$CN} {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 0;
	border-bottom: 1px solid $colorBorder;
}

.#{$CN}__text {
	@include LG__LG-Strong;
	margin: 0;
}
