@import "../../../../../app/App/css/variables";

$CN: payment-name-block;

.#{$CN} {
	display: flex;
	align-items: center;
	gap: 16px;
}

.#{$CN}-logo {
	border-radius: 6px;
}

.#{$CN}-name {
	@include Heading__Heading-5;
	margin: 0;
}

.#{$CN}-site {
	@include SM__SM-Strong;
	margin: 0;
}
