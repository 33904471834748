@import "../../../../../../../app/App/css/variables";
@import "../../../../../common/landing-common";

$CN: land-const-prog-bullets-blocks;

.#{$CN} {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
	gap: 20px;
}
@include media-monitor() {
	.#{$CN} {
		gap: 30px;
	}
}