@import "../../../../../../app/App/css/variables";
@import "../../../../common/landing-common";

$CN: land-const-existence-section-content;

.#{$CN} {
	display: grid;
	gap: 30px;
}
@include media-monitor() {
	.#{$CN} {
		grid-template-columns: 360px 1fr;
	}
}
