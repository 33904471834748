$CN: 'video-frame';

.#{$CN}__wrapper {
	position: relative;
	width: 100%;
	padding-top: 56%;
}

.#{$CN}__iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: none;
}

.#{$CN}__video {
	display: block;
	max-width: 100%;
	max-height: 500px;
}