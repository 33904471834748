@import "../../../../../app/App/css/variables";

$CN: visit-training-card;

.#{$CN} {
	display: flex;
}

.#{$CN}__num-wrapper {
	position: relative;
	margin: 0 16px 0 0;

	&--line {
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: calc(50% - 1px);
			width: 2px;
			height: 100%;
			background-color: $colorSplit;
		}
	}
}

.#{$CN}__num {
	position: relative;
	min-width: 30px;
	@include Base__Base-Strong;
	color: white;
	text-align: center;
	padding: 4px 0;
	border-radius: 15px;

	&--progress {
		background-color: $colorBlue6;
	}

	&--success {
		background-color: $colorGreen6;
	}

	&--closed {
		background-color: $colorTextSecondary;
	}
}

.#{$CN}__wrapper {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin: 0 0 16px 0;
}

.#{$CN}__top {
	display: flex;
	justify-content: space-between;
}

.#{$CN}__text-with-icon {
	display: flex;
	align-items: center;
	gap: 4px;
	@include Base__Base-Normal;
	color: $colorTextSecondary;
	margin: 0;
}

.#{$CN}__icon {
	width: 16px;

	&--progress {
		color: $colorBlue6;
	}

	&--success {
		color: $colorGreen6;
	}

	&--closed {
		color: $colorTextSecondary;
	}
}

.#{$CN}__training-name {
	@include LG__LG-Strong;
	color: $colorText;
}
