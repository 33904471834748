@import '../../app/App/css/variables.scss';

.Footer {
	width: 100%;
	height: 68px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	padding: 24px 65px;
	border-top: 1px solid var(--colorSplit, rgba(5, 25, 46, 0.06));
	background: var(--colorBgLayout, #f8f9fc);

	@media only screen and (max-device-width: 768px) {
		display: none;
	}

	&__Left {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 10px;
		color: var(--colorTextSecondary, rgba(5, 25, 46, 0.65));
		@include SM__SM-Normal;
	}

	&__Right {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		gap: 10px;

		&_Hidden {
			display: none;
		}
	}

	&__Icons {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 10px;
	}

	&__Copyright {
		padding-right: 10px;
		border-right: 1px solid rgba(5, 25, 46, 0.06);
	}

	&__NeedHelp {
		@include SM__SM-Strong;
	}

	&__HelpCenter {
		@include SM__SM-Normal;
		color: var(--colorPrimaryBase, #1677ff);
		padding-right: 10px;
		border-right: 1px solid rgba(5, 25, 46, 0.06);
	}

	&__We {
		@include SM__SM-Strong;
	}
}

.FooterMobile {
	width: 100%;
  display: flex;
	flex-direction: column;
	flex-wrap: wrap;
  align-items: center;
  padding: 20px;
  gap: 12px;

	@media only screen and (min-device-width: 768px) {
		display: none;
	}

  &__FirstLine {
    width: 100%;
    display: flex;
	  flex-wrap: wrap;
    justify-content: space-between;
  }

  &__SecondLine {
    width: 100%;
    display: flex;
	  flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }

  &__Agreement {
    color: var(--colorTextSecondary, rgba(5, 25, 46, 0.65));
		@include SM__SM-Normal;
  }

  &__Copyright {
		color: var(--colorTextSecondary, rgba(5, 25, 46, 0.65));
		@include SM__SM-Normal;
	}

	&__Icons {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		gap: 10px;
	}

	&__HelpCenter {
		@include SM__SM-Normal;
		color: var(--colorPrimaryBase, #1677ff);
	}
}
