@import '../../../../app/App/css/variables.scss';

.LessonCard {
	width: 100%;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	border-radius: 20px;
	background: var(--colorBorderBg, #fff);
	box-shadow: 0px 2px 4px 0px rgba(34, 52, 71, 0.03),
		0px 1px 6px -1px rgba(34, 52, 71, 0.03),
		0px 0px 1px 1px rgba(34, 52, 71, 0.05);

	@media only screen and (min-device-width: 768px) {
		width: 80%;
		height: 134px;
		flex-direction: row;
		gap: 10px;
	}

	&__Image {
		flex: 0 0 160px;
		align-self: center;
		display: flex;
		justify-content: center;
		align-items: center;

		@media only screen and (min-device-width: 768px) {
			flex: 0 0 176px;
			border-bottom: none;
			border-right: 1px solid
				var(--colorFillSecondary, rgba(5, 15, 46, 0.06));
		}
	}

	&__Content {
		flex: 2 1 auto;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;
		padding: 20px;
		overflow: hidden;
		border-top: 1px solid var(--colorFillSecondary, rgba(5, 15, 46, 0.06));

    @media only screen and (min-device-width: 768px) {
			padding: 10px 0;
		}
	}

	&__Button {
		align-self: flex-start;
		padding-left: 20px;
		padding-bottom: 20px;

		@media only screen and (min-device-width: 768px) {
			padding-left: 0;
			padding-bottom: 0;
			align-self: center;
			display: flex;
			justify-content: center;
			align-items: center;
			padding-right: 20px;
		}
	}
}

.Content {
	

	&__LessonNo {
		@include SM__SM-Normal;
		color: var(--colorPrimaryHover, #4096ff);
	}

	&__Title {
		@include Heading__Heading-5;
	}

	&__SubTitle {
    flex: 0 1 44px;
		@include Base__Base-Normal;
    color: var(--colorTextSecondary, rgba(5, 25, 46, 0.65));
		overflow: hidden;
		display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
	}
}
