$CN: 'exercise-true-false-content';

.#{$CN} {
	max-width: 600px;
	margin: 0 auto;
}

/* УТВЕРЖДЕНИЕ */
.#{$CN}__statement {
	background-color: #F9F9F9;
	padding: 15px;
	border-radius: 10px;
}

@media (min-width: 500px) {
	.#{$CN}__statement {
		padding: 30px;
	}
}

.#{$CN}__statement-text {
	font-size: 20px;
	line-height: 30px;
	font-weight: 500;
	text-align: center;
	margin: 0;
}

.#{$CN}__statement-text--big {
	font-size: 20px;
	line-height: 30px;
}

@media (min-width: 500px) {
	.#{$CN}__statement-text--big {
		font-size: 26px;
		line-height: 38px;
	}
}

.#{$CN}__statement-image {
	display: block;
	width: 100%;
	max-width: 350px;
	//max-height: 350px;
	margin: 10px auto 0 auto;
	border-radius: 2px;
}
@media (min-width: 500px) {
	.#{$CN}__statement-image {
		margin-top: 20px;
	}
}

.#{$CN}__wrong-statement, .#{$CN}__right-statement {
  text-align: center;
  margin: 15px 0 0 0;
}
.#{$CN}__wrong-statement {
  color: #FF0000;
}
.#{$CN}__right-statement {
  color: #64B625;
}

/* ПОДВАЛ */
.#{$CN}__bottom {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 25px;
}

@media (min-width: 500px) {
	.#{$CN}__bottom {
		margin-top: 35px;
	}
}

.#{$CN}__bottom-counter {
	display: none;
}

@media (min-width: 450px) {
	.#{$CN}__bottom-counter {
		display: block;
	}
}

@media (min-width: 600px) {
	.#{$CN}__bottom-counter {
		min-width: 90px;
	}
}

.#{$CN}__bottom-center {
	display: grid;
	grid-auto-flow: column;
	gap: 10px;
}

.#{$CN}__bottom-true-button {
	color: #2B981A;
	border-color: #66B77D;

	&:hover {
		color: #268d14;
		border-color: #5dad74;
	}
}
