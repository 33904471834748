$CN: main-menu-list;

.#{$CN} {
	flex-grow: 1;
	font-family: 'Montserrat', sans-serif;
	border: none;
	border-inline-end: none !important;
}

.#{$CN}__counter {
	.ant-scroll-number-only-unit {
		line-height: 19px;
	}
}
