$CN: preview-exercise-aside;

.#{$CN} {
	border-radius: 4px;
}
@media (min-width: 1100px) {
	.#{$CN} {
		position: fixed;
		top: 0;
		bottom: 24px;
		overflow-y: auto;
		overflow-x: hidden;
		width: 250px;
		background-color: white;
		padding: 24px 24px 24px 24px;
	}
}

.#{$CN}__top {
	margin: 0 0 20px 0;
}

.#{$CN}__bottom {
	display: flex;
	gap: 30px;
	overflow: auto;
	padding: 0 0 15px 0;
	margin: 0 0 20px 0;
}
@media (min-width: 1100px) {
	.#{$CN}__bottom {
		display: block;
		padding-bottom: 0;
		margin-bottom: 0;
	}
}

.#{$CN}__exercise {
	cursor: pointer;

	&:hover .#{$CN}__outer-circle:not(.#{$CN}__outer-circle--current) {
		border-color: #cbdde7;
	}
}
@media (min-width: 1100px) {
	.#{$CN}__exercise {
		display: flex;
	}
}

.#{$CN}__exercise-top {
	display: flex;
	align-items: center;
}
@media (min-width: 1100px) {
	.#{$CN}__exercise-top {
		flex-direction: column;
		margin: 0 10px 0 0;
	}
}

// НОМЕР УПРАЖНЕНИЯ
.#{$CN}__outer-circle {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border: 1px solid transparent;
	border-radius: 50%;
	transition: border-color .25s;
}
.#{$CN}__outer-circle--current {
	border-color: #1DABF2;
}
.#{$CN}__circle {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	font-weight: 600;
	text-align: center;
	color: #a3a4ab;
	margin: 0;
	border: 1px solid #a3a4ab;
	border-radius: 50%;
}
.#{$CN}__circle--success {
	border-color: #66B77D;
}

// ЛИНИЯ
.#{$CN}__line {
	flex-grow: 1;
	height: 1px;
	background-color: #9CA1A9;
	margin: 0 -25px 0 5px;
}
@media (min-width: 1100px) {
	.#{$CN}__line {
		height: 50px;
		width: 1px;
		margin: 5px 0 5px 0;
	}
}
.#{$CN}__line--success {
	background-color: #66B77D;
}

// ТЕКСТ УПРАЖНЕНИЯ
.#{$CN}__exercise-text {
	font-size: 15px;
	line-height: 21px;
	color: #191D23;
	white-space: nowrap;
	margin: 10px 0 0  0;
}
@media (min-width: 1100px) {
	.#{$CN}__exercise-text {
		margin: 9px 0 0  0;
	}
}

// ВРЕМЯ
.#{$CN}__time {
	font-size: 13px;
	line-height: 20px;
	color: #a3a4ab;
	margin: 3px 0 0 0;
}

.#{$CN}__lessons-nav {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	font-size: 14px;
	line-height: 18px;
	font-weight: 500;
	margin: 0 0 1rem 0;
}