@import '../../../../app/App/css/variables.scss';

.Title {
	width: 100%;
	height: 138px;
	padding: 20px;
	position: relative;
	border-radius: 20px;
	background: linear-gradient(
			0deg,
			rgba(150, 231, 136, 0.52) 0%,
			rgba(150, 231, 136, 0.52) 100%
		),
		#fff;
	box-shadow: 0px 2px 4px 0px rgba(34, 52, 71, 0.03),
		0px 1px 6px -1px rgba(34, 52, 71, 0.03),
		0px 0px 1px 1px rgba(34, 52, 71, 0.05);

	@media only screen and (min-device-width: 768px) {
		height: 180px;
		padding: 30px;
	}

	&__Text {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 12px;
	}

	&__Title {
		@include Heading__Heading-3;

		@media only screen and (min-device-width: 768px) {
			@include Heading__Heading-2;
			width: 85%;
		}
	}

	&__SubTitle {
		width: 82%;
		overflow: hidden;
		color: var(--colorTextDescription, rgba(5, 25, 46, 0.45));
		text-overflow: ellipsis;
		white-space: nowrap;

		@include Base__Base-Normal;
	}

	&__Image {
		display: none;

		@media only screen and (min-device-width: 768px) {
			display: block;
			position: absolute;
			bottom: 0px;
			right: 20px;
		}
	}
}

