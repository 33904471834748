@import "../../App/css/variables";

$CN: user-menu;

.user-menu-general {
	display: flex;
}

.#{$CN} {
	position: relative;
	min-width: 250px;
	padding: 10px 0 0 0;
}

.#{$CN}__tip-w {
	position: absolute;
	right: 33px;
	top: 0;
	width: 22px;
	height: 10px;
	overflow: hidden;
}
.#{$CN}__tip {
	position: absolute;
	right: 3px;
	top: 3px;
	width: 16px;
	height: 16px;
	//background-color: #245BA8;
	background-color: white;
	transform: rotate(45deg);
	border-radius: 2px;
}

.#{$CN}__inner {
	background-color: #fff;
	overflow: hidden;
	border-radius: 8px;
	box-shadow: 0 6px 16px 0 rgba(0, 0, 0, .08),0 3px 6px -4px rgba(0,0,0,.12),0 9px 28px 8px rgba(0,0,0,.05);
}

.#{$CN}__user {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
	width: 100%;
	background-color: #245BA8;
	padding: 16px 16px;
	border: none;

	&:hover {
		background-color: #1a4e96;
	}
}

.#{$CN}__user-texts {
	flex-grow: 1;
}

.#{$CN}__user-name, .#{$CN}__user-email {
	color: white;
	text-align: left;
	margin: 0;
}
.#{$CN}__user-name {
	@include Base__Base-Strong;
}
.#{$CN}__user-email {
	@include SM__SM-Normal;
}

.#{$CN}__block-wrapper {

}

.#{$CN}__block-left {

}

.#{$CN}__block-right {

}

.#{$CN}__plan-name, .#{$CN}__plan-action {
	text-align: left;
	margin: 0;
}
.#{$CN}__plan-name {
	color: $colorText;
	@include Base__Base-Strong;
}
.#{$CN}__plan-action {
	color: $colorPrimary;
	@include SM__SM-Normal;
}

.#{$CN}__block-wrapper-g {
	padding: 3px 0;
}
.#{$CN}__block-wrapper-g:not(:last-child) {
	border-bottom: 1px solid $colorSplit;
}

.#{$CN}__block-wrapper {
	display: flex;
	color: $colorText;
	@include Base__Base-Normal;
	cursor: pointer;
	padding: 6px 16px;

	&:hover {
		color: $colorPrimary;
		background: #F8F9FC;

		.#{$CN}__block-left {
			color: $colorPrimary;
		}
	}
}

.#{$CN}__block-left {
	color: $colorIcon;
	padding: 0 8px 0 0;
}

.#{$CN}__block-right {

}

