$CN: generator;

.#{$CN} {
	background-color: #F3F7FA;
	padding: 10px 15px;
	margin: 10px 0 0 0;
	border-radius: 3px;
}

.#{$CN}__header {
	font-size: 16px;
	line-height: 22px;
	font-weight: 600;
	user-select: none;
	cursor: pointer;
	margin: 0;
}

.#{$CN}__collapse-icon {
	vertical-align: middle;
	transition: transform .2s;
	margin: 0 0 0 6px;

	&--open {
		transform: rotate(90deg);
	}
}

.#{$CN}__content {
	margin: 7px 0 0 0;
}

.#{$CN}__intro-text {
	font-weight: 500;
	margin: 0 0 8px 0;
}

.#{$CN}__examples {
	display: grid;
	gap: 8px;
	background-color: white;
	padding: 7px 10px;
	margin: 0 0 10px 0;
	border-radius: 3px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
}

.#{$CN}__example {
	font-size: 13px;
	line-height: 17px;
	font-weight: 500;
	margin: 0;
}

.#{$CN}__form {
	display: grid;
	gap: 5px;
}

.#{$CN}__form-bottom {
	display: flex;
	justify-content: flex-end;
}

.#{$CN}__error {
	color: #b20000;
	background-color: #fae8e8;
	padding: 2px 10px;
	margin: 0;
	border-radius: 3px;
}