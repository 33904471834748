.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 52px;
    height: 52px;
	background-color: white;
    border: 1px solid rgba(5, 25, 46, 0.15);
    border-radius: 50%;

    &--current {
        background-color: #E6F4FF;
        border-color: #91CAFF;
    }
}

.icon {
    color: #1677FF;

    svg {
        width: 28px;
        height: 28px;
    }
}