@import "../../app/App/css/variables";

$CN: uform;

// Белая обёртка
.#{$CN}__white-wrapper {
	background-color: white;
	padding: 16px;
	border: 1px solid $colorBorder;
	border-radius: 8px;
}
@media (min-width: 500px) {
	.#{$CN}__white-wrapper {
		padding: 32px;
	}
}

// Белая обёртка
.#{$CN}__lr {

}
.#{$CN}__lr-left {

}
.#{$CN}__lr-divider {
	height: 1px;
	background-color: $colorBorder;
	margin: 24px 0;
}
.#{$CN}__lr-right {
	flex-grow: 1;
}
@media (min-width: 600px) {
	.#{$CN}__lr {
		display: flex;
		justify-content: stretch;
	}
	.#{$CN}__lr-left {

	}
	.#{$CN}__lr-divider {
		width: 1px;
		height: auto;
		margin: 0 24px;
	}
	.#{$CN}__lr-right {

	}
}

// Ряд формы
.#{$CN}__row {
	.ant-form-item {
		margin-bottom: 0;
	}

	&--top-offset {
		margin-top: 15px;
	}

	&--many-cell {
		display: grid;
		gap: 24px;
		grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
	}
}

// Заголовок блока с полями ввода
.#{$CN}__block-header {
	@include Heading__Heading-5;
	color: $colorText;
	margin: 0 0 12px 0;
}

// Подпись ниже поля
.#{$CN}__input-caption {
	@include Base__Base-Normal;
	color: $colorTextDescription;
	margin: 8px 0 0 0;
}

// Разделитель рядов
.#{$CN}__divider {
	height: 1px;
	background-color: $colorSplit;
	margin: 16px 0;
}

// Обёртка для кнопок внизу формы. Ставится после формы с серой обводкой.
.#{$CN}__bottom-after-gray {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 12px;
	margin: 12px 0 0 0;
}

// Обёртка c серой обводкой сверху для кнопок внизу формы.
.#{$CN}__bottom-with-border {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 12px;
	padding: 16px 0 0 0;
	margin: 32px 0 0 0;
	border-top: 1px solid $colorBorder;

	.ant-form-item {
		margin-bottom: 0;
	}
}

// Контейнер для кнопок для их разделения
.#{$CN}__buttons-container {
	display: flex;
	gap: 12px;
	flex-wrap: wrap;
}

// Обёртка для общей ошибки
.#{$CN}__general-error {
	margin: 12px 0 0 0;
}

// Контейнер для кнопок внизу формы live-урока
.#{$CN}__buttons-container-wrapper {
	display: flex;
	justify-content: space-between;
	margin: 12px 0 0 0;
}
