@import "../../app/App/css/variables";

$CN: my-schools;

// ===== ОБЁРТКА КАРТОЧЕК =====

.#{$CN} {
	display: grid;
	gap: 24px;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}


// ===== КАРТОЧКА ШКОЛЫ =====

.#{$CN}__card {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 32px;
}

.#{$CN}__card-avatar {
	color: $colorPrimaryBase;
	background: $colorPrimaryBg;

	.anticon svg {
		width: 32px !important;
	}
}

.#{$CN}__card-domain {
	@include SM__SM-Strong;
	color: $colorPrimary;
	margin: 20px 0 0 0;
}

.#{$CN}__card-name {
	@include Heading__Heading-5;
	color: $colorTextHeading;
	margin: 4px 0 0 0;
}

.#{$CN}__card-go-to-school-wrapper {
	width: 100%;
	margin: 20px 0 12px 0;
}
.#{$CN}__card-go-to-school-link {
	display: block;
}
