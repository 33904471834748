$CN: 'exercise-audio-answer-answer';

.#{$CN} {
	margin-top: 50px;
}

.#{$CN}__header {
	font-size: 20px;
	line-height: 28px;
	font-weight: 600;
	margin: 0 0 5px 0;
}

.#{$CN}__top {
	margin: 0 0 15px 0;
}
