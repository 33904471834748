@import "../../../../../app/App/css/variables";
@import "../../../common/landing-common";

$CN: landing-section-header;

.#{$CN} {
	display: grid;
	gap: 10px;
	text-align: center;
	width: 100%;
}

.#{$CN}__image {
	object-fit: cover;
	max-width: 80px;
	margin: 0 auto;
}

.#{$CN}__header {
	font-size: 56px;
	font-weight: 700;
	line-height: 62px;
	letter-spacing: -2px;
	white-space: pre-wrap;
	margin: 0;

	&--white {
		color: white;
	}

	@include allThemeGradientClassesForText();
}
