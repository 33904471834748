@import "../../../app/App/css/variables";

$CN: u-card-block;

.#{$CN} {
	display: block;
	background-color: $colorBgContainer;
	cursor: default;
	overflow: hidden;
	border-radius: 8px;
}

.#{$CN}--bg-white {
	//background-color: white;
}
.#{$CN}--bg-green {
	background-color: #F8FFEE;
}
.#{$CN}--bg-red {
	background-color: #FDF1F6;
}
.#{$CN}--bg-orange {
	background-color: #ffefc0;
}
.#{$CN}--bg-blue {
	background-color: #c0e5ff;
}
.#{$CN}--bg-gray {
	background-color: #F3F4F8;
}

.#{$CN}--border {
	border: 1px solid transparent;

	&-green {
		border-color: $colorSuccessBorderHover;
	}
	&-red {
		border-color: #ff8383;
	}
	&-orange {
		border-color: $colorOrange3;
	}
	&-blue {
		border-color: $colorPrimaryBorderHover;
	}
	&-gray {
		border-color: $colorBorder;
	}
}

.#{$CN}--hover {
	cursor: pointer;
	box-shadow: 0 0 1px 1px rgba(34, 52, 71, 0.05),
	0 1px 6px -1px rgba(34, 52, 71, 0.03),
	0 2px 4px rgba(34, 52, 71, 0.03);
	transition: box-shadow .2s;

	&:hover {
		box-shadow: 0 4px 14px rgba(0, 0, 0, 0.05),
		0 3px 8px rgba(0, 0, 0, 0.08);
	}
}

.#{$CN}--shadow {
	box-shadow: 0 0 1px 1px rgba(34, 52, 71, 0.05),
	0 1px 6px -1px rgba(34, 52, 71, 0.03),
	0 2px 4px rgba(34, 52, 71, 0.03);
}

.#{$CN}--shadow-green {
	box-shadow: 0 0 1px 1px rgba(34, 52, 71, 0.05),
	0 1px 6px -1px rgba(34, 52, 71, 0.03),
	0 2px 4px rgba(34, 52, 71, 0.03);
}
.#{$CN}--shadow-red {
	box-shadow: 0 0 1px 1px rgba(34, 52, 71, 0.05),
	0 1px 6px -1px rgba(34, 52, 71, 0.03),
	0 2px 4px rgba(34, 52, 71, 0.03);
}
.#{$CN}--shadow-orange {
	box-shadow: 0 0 1px 1px rgba(34, 52, 71, 0.05),
	0 1px 6px -1px rgba(34, 52, 71, 0.03),
	0 2px 4px rgba(34, 52, 71, 0.03);
}
.#{$CN}--shadow-blue {
	box-shadow: 0 0 1px 1px rgba(34, 52, 71, 0.05),
	0 1px 6px -1px rgba(34, 52, 71, 0.03),
	0 2px 4px rgba(34, 52, 71, 0.03);
}
.#{$CN}--shadow-gray {
	box-shadow: 0 0 1px 1px rgba(34, 52, 71, 0.05),
	0 1px 6px -1px rgba(34, 52, 71, 0.03),
	0 2px 4px rgba(34, 52, 71, 0.03);
}

.#{$CN}--padding {
	&-small {
		padding: 12px 16px;
	}
	&-middle {
		padding: 24px 24px 24px 24px;
	}
}
