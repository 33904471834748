@import "../../../../../../app/App/css/variables";
@import "../../../../common/landing-common";

$CN: landing-time-main-img;

.#{$CN} {
	display: flex;
	align-items: flex-end;
	overflow: hidden;
	border-radius: 20px;

	@include allThemeGradientClasses();
}
@include media-monitor() {
	.#{$CN} {

	}
}

.#{$CN}__img {
	object-fit: cover;
	width: 100%;
	height: 100%;
}