@import "../../../../../app/App/css/variables";

$CN: land-const-section-outer;

.#{$CN} {
	&--middle-width, &--big-width {
		margin: 0 auto;
	}
	&--middle-width {
		max-width: 1200px;
	}
	&--big-width {
		max-width: 1400px;
	}
}

.#{$CN}__top {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.#{$CN}__help {
	display: flex;
	justify-content: space-between;
	gap: 10px;
	align-items: center;
	font-size: 14px;
	line-height: 20px;
	font-weight: 500;
	max-width: 678px;
	padding: 10px 20px;
	border-radius: 12px;

	&--opened {
		background-color: rgba(254, 208, 43, 0.37);

		.#{$CN}__help-sign {
			color: rgb(189, 128, 0)
		}
	}

	&--closed {
		background-color: rgb(220, 220, 220);

		.#{$CN}__help-sign {
			color: rgb(150, 150, 150)
		}
	}
}

.#{$CN}__help-sign {
	font-size: 24px;
}

.#{$CN}__visibility-button {
	min-width: 40px;
	margin: 0 0 0 8px;
}

.#{$CN}__content {
	margin: 20px 0 0 0;
}

.#{$CN}__texts {
	display: grid;
	gap: 10px;
}

.#{$CN}__text {
	margin: 0;
}
