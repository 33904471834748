$CN: 'exercise-gaps-content';

.#{$CN}__text-blocks {
  display: grid;
  gap: 20px;
	margin-top: 20px;
}

.#{$CN}__gap {
  display: inline-block;
  min-width: 40px;
  background-color: #ffffff;
  padding: 1px 4px;
  border: 1px solid #d5d8dc;
  border-radius: 4px;

  &:focus {
    border-color: #1DABF2;
  }
}

.#{$CN}__gap--success {
  background-color: #F6FFED;
  border-color: #8DE748;
}
.#{$CN}__gap--fail {
  background-color: #FFF1F0;
  border-color: #FFC0C0;
}

.#{$CN}__right-answer {
  display: none;
  font-weight: bold;
  color: white;
  background-color: #72CC19;
  padding: 2px 3px;
  margin: 0 0 0 2px;
  border-radius: 4px;
}
.#{$CN}__right-answer--open {
  display: inline-block;
}

.#{$CN}__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0 0 0;
}

.#{$CN}__bottom-attempts {
  margin: 0;
}

.#{$CN}__bottom-open-gaps {
	display: none;
	margin: 0 15px 0 0;
}
@media (min-width: 500px) {
	.#{$CN}__bottom-open-gaps {
		display: inline-block;
	}
}