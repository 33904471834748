.OrderButton {
	width: 100%;
	color: #fff;
	background: #ff9739;
	border: none;

	span {
		color: #fff;
	}

	&:hover {
		span {
			color: #fff;
		}
	}
}

.OrderData {
	display: flex;
	justify-content: space-between;
	padding: 10px 0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	margin-bottom: 30px;

	img {
		width: 100%;
	}

	span:last-child {
		font-weight: bold;
		text-align: right;
	}
}
