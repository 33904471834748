@import "app/App/css/variables";
$CN: ui-tabs;


.#{$CN} {
    .ant-tabs {
        color: $colorText;
    }

    .ant-tabs-tab:hover,
    .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: $colorPrimaryBase;
    }
}