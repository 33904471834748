@import "../../../../../../app/App/css/variables";
@import "../../../../common/landing-common";

$CN: land-const-editable-text;

.#{$CN} {
	display: inline-block;
	width: 100%;
	background-color: rgba(0, 0, 0, .05);
	white-space: pre-wrap;

	&:hover, &:focus {
		background-color: rgba(0, 0, 0, .1);
	}

	&:empty::before {
		content: attr(data-placeholder);
	}
}
