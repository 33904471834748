@import "../../../../../app/App/css/variables";
@import "../../../common/landing-common";

$CN: landing-video-section;

.#{$CN} {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 20px;
}
@include media-monitor() {
	.#{$CN} {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
}

.#{$CN}__top-part {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12px;
}
@include media-monitor() {
	.#{$CN}__top-part {
		order: 1;
		min-height: 385px;
		max-height: 500px;
		padding: 24px 40px;
	}
}

.#{$CN}__description {
	font-size: 20px;
	font-weight: 500;
	line-height: 28px;
	white-space: pre-wrap;
	margin: 0;
}

.#{$CN}__iframe {
	overflow: hidden;
	border-radius: 16px;
}

