@import "../../../../app/App/css/variables";

$CN: training-card-admin;

.#{$CN} {
	padding: 0;
}

.#{$CN}__content {
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 12px;
}

@media (min-width: 550px) {
	.#{$CN}__content {
		flex-direction: row;
		gap: 28px;
		padding: 16px;
	}
}

.#{$CN}__cover-wrapper {
	flex-grow: 10;
	width: 100%;
	height: 134px;
}

@media (min-width: 550px) {
	.#{$CN}__cover-wrapper {
		width: 100px;
		min-width: 100px;
		max-width: 100px;
		height: 100px;
	}
}

.#{$CN}__right-content {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.#{$CN}__header {
	@include Heading__Heading-5;
	color: $colorTextHeading;
	margin: 8px 0 0 0;
}

.#{$CN}__description {
	@include Base__Base-Normal;
	color: $colorTextSecondary;
	margin: 4px 0 0 0;
}

.#{$CN}__buttons {
	display: flex;
	flex-wrap: wrap;
	column-gap: 4px;
	row-gap: 4px;
	justify-content: space-between;
	margin: 8px 0 0 0;
}

.#{$CN}__left-buttons {
	display: flex;
	flex-wrap: wrap;
	column-gap: 4px;
	row-gap: 4px;
}

.#{$CN}__groups-wrapper {
	padding: 12px 0 0 0;
	border-top: 1px solid $colorSplit;
}
