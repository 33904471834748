.page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: rgba(248, 249, 252, 1);
}

.generalWrapper {
    width: 100%;
    max-width: 750px;
    padding: 24px;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(34, 52, 71, 0.03), 0 1px 6px -1px rgba(34, 52, 71, 0.03), 0px 0px 1px 1px rgba(34, 52, 71, 0.05);
}

.signRound {
    display: inline-block;
    padding: 14px;
    border-radius: 50%;
}
.signRoundFail {
    background-color: rgba(255, 241, 240, 1);
}
.signRoundSuccess {
    background-color: rgba(246, 255, 237, 1);
}
.signIcon {
    display: block;
}

.panelHeader {
    color: rgba(5, 25, 46, 0.88);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin: 0 0 16px 0;
}

.errorWrapper {
    display: grid;
    gap: 24px;
}

.errorDetailsText {
    color: rgba(5, 26, 46, 0.88);
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    margin: 0;
}

.errorBottom {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 28px;
}

.errorSupportText {
    color: #1677FF;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    margin: 0;
}

.successWrapper {
    overflow: hidden;
    border: 1px solid rgb(230, 230, 230);
    border-radius: 8px;
}

.successRow {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid rgb(230, 230, 230);
}

.successParagraphHeader {
    color: rgba(5, 25, 46, 0.65);
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    width: 200px;
    padding: 16px;
    margin: 0;
}

.successParagraphText {
    color: rgba(5, 26, 46, 0.88);
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    padding: 16px;
    margin: 0;
}

.successBottom {
    display: flex;
    justify-content: flex-end;
    margin-top: 18px;
}
