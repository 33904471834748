@import "../../app/App/css/variables";

$CN: 'progress';

.#{$CN} {
	display: flex;
	align-items: center;
}

.#{$CN}__bar {
	flex-grow: 1;
	height: 8px;
	min-width: 150px;
	overflow: hidden;
	border-radius: 4px;
}

.#{$CN}__gradient {
	display: flex;
	justify-content: right;
	background-image: linear-gradient(90deg, #1DABF2, #87D068);
	width: 100%;
	height: 100%;
}

.#{$CN}__space {
	background-color: #E9EBF0;
}

.#{$CN}__text {
	@include SM__SM-Strong;
	padding: 0 6px;
	border-radius: 4px;
	color: $colorGreen8;
	white-space: nowrap;
	background-color: $colorGreen2;
	margin: 0 0 0 8px;
	border: 1px solid $colorGreen5;
}
