@import "../../../app/App/css/variables";

$CN: header-tabs;

.#{$CN} {
	display: flex;
	flex-wrap: wrap;
	border-bottom: 1px solid $colorSplit;
}

.#{$CN}__tab {
	display: inline-block;
	font-size: 14px;
	line-height: 20px;
	font-weight: 500;
	color: $colorText;
	padding: 12px 12px;
	margin: 4px 0 0 0;
	border-bottom: 2px solid transparent;
	transform: translateY(1px);

	&:hover:not(.#{$CN}__tab--current) {
		border-bottom-width: 1px;
		border-bottom-color: $colorBorder;
	}

	&--current {
		color: $colorPrimaryBase;
		border-bottom-color: $colorPrimaryBase;
	}
}
