@import "../../../../../app/App/css/variables";

$CN: form-help;

.#{$CN} {
	background-color: $colorInfoBg;
	padding: 24px;
	border-radius: 8px;
}

.#{$CN}__static-header {
	font-size: 10px;
	line-height: 18px;
	font-weight: 700;
	text-transform: uppercase;
	color: $colorPrimaryBase;
	margin: 0 0 24px 0;
}

.#{$CN}__content {
	display: grid;
	gap: 16px;
}

.#{$CN}__header {
	@include XL__XL-Strong();
	color: $colorText;
	margin: 0;
}

.#{$CN}__text {
	@include SM__SM-Normal();
	color: $colorTextSecondary;
	margin: 0;
}

.#{$CN}__list {
	display: grid;
	gap: 4px;
}

.#{$CN}__list-item {
	@include SM__SM-Normal();
	color: $colorTextSecondary;
	margin: 0;
}

.#{$CN}__card {
	display: grid;
	gap: 8px;
	background-color: rgba(186, 224, 255, .5);
	padding: 16px;
	border-radius: 8px;
}

.#{$CN}__card-header-1 {
	font-size: 10px;
	line-height: 16px;
	font-weight: 700;
	color: $colorPrimaryBase;
}

.#{$CN}__card-header-2-wrapper {
	display: flex;
	gap: 4px;
	margin: 0;
}

.#{$CN}__card-header-2-icon {
	color: $colorPrimaryBase;
}

.#{$CN}__card-header-2 {
	@include SM__SM-Strong();
	color: $colorText;
	margin: 0;
}

.#{$CN}__card-content {
	display: grid;
	gap: 8px;
}
