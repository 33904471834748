$CN: s-history-modal;

.#{$CN} {

}

.#{$CN}__icon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 52px;
	height: 52px;
	border: 1px solid rgba(5, 25, 46, 0.15)	;
	border-radius: 50%;
	margin: 0 auto;
}

.#{$CN}__header {
	font-size: 24px;
	text-align: center;
	color: rgba(5, 25, 46, 0.88);
	font-weight: 600;
	line-height: 32px;
	margin: 8px 0 0 0;

}

.#{$CN}__content {
	margin: 8px 0 24px 0;
}

.#{$CN}__bottom {
	display: flex;
	justify-content: center;
	gap: 16px;
}