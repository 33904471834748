$CN: 'exercise-choose-word-content';

.#{$CN}__text-blocks {
  display: grid;
  gap: 20px;
	margin-top: 20px;
}

.#{$CN}__select {
	margin: 0 2px;
	border: 1px solid #b9bec2;
	border-radius: 4px;
}

.#{$CN}__select--success {
  background-color: #F6FFED;
  border-color: #8DE748;
}
.#{$CN}__select--fail {
  background-color: #FFF1F0;
  border-color: #FFC0C0;
}

.#{$CN}__right-answer {
  display: none;
  font-weight: bold;
  color: white;
  background-color: #72CC19;
  padding: 2px 3px;
  margin: 0 0 0 2px;
  border-radius: 4px;
}
.#{$CN}__right-answer--open {
  display: inline-block;
}

.#{$CN}__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0 0 0;
}

.#{$CN}__bottom-attempts {
  margin: 0;
}

.#{$CN}__bottom-open-gaps {
  margin: 0 15px 0 0;
}
