$CN: 'gaps-flasher';

// Обёртка контента дублирующего текст из редактора
.#{$CN} {
	position: relative;
	overflow: hidden;
}

// Обёртка дублирующего контента
.#{$CN}__text-area {
	position: absolute;
	top: 54px;
	left: 16px;
	right: 16px;
	z-index: 0;
	color: transparent !important;

	* {
		color: transparent !important;
	}
}

// Обёртка редактора чтобы задать ему z-index
.#{$CN}__editor-wrapper {
	position: relative;
	z-index: 1;
}

// Элемент подсвечивающий текст в фигурных скобках
.#{$CN}__flash {
	transform: translateX(-10px);
	border-radius: 1px;
	transition: background-color .2s, box-shadow .2s;
}
.#{$CN}__flash--ok {
	background-color: #fff1c8;
	box-shadow: 0 1px 0 2px #fff1c8;
}
.#{$CN}__flash--warning {
	background-color: #ffd0c8;
	box-shadow: 0 1px 0 2px #ffd0c8;
}


