@import "../../../../../../app/App/css/variables";
@import "../common/common";

$CN: tariff-card-description;

.#{$CN} {
	font-size: 20px;
	line-height: 28px;
	font-weight: 500;
	margin: 15px 0 0 0;

	&--white {
		color: white;
	}
}
