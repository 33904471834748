$CN: mobile-menu-button;

.#{$CN} {
	font-size: 24px;
	cursor: pointer;
}

@media (min-width: 500px) {
	.#{$CN} {
		display: none;
	}
}
