@import "../../../../../../app/App/css/variables";
@import "../../../../common/landing-common";

$CN: land-const-program-section;

.#{$CN} {
	padding: 60px 20px;
	border-radius: 20px;

	@include allThemeGradientClasses();
}
@include media-monitor() {
	.#{$CN} {
		padding: 60px 66px;
	}
}
