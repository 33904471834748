@import "../../../../../app/App/css/variables";

$CN: group-stuff-table;

.#{$CN} {

}

.#{$CN}__elems-wrapper {
	display: flex;
	gap: 8px;
	align-items: center;
}

.#{$CN}__avatar {
	display: flex;
	gap: 8px;
	align-items: center;
	width: 24px;
	height: 24px;
	border-radius: 50%;
}

.#{$CN}__text {
	margin: 0;
}

.#{$CN}__actual-pupil-icon {
	color: $colorSuccess;
}

.#{$CN}__actions-wrapper {
	display: flex;
	gap: 16px;
	align-items: center;
}

.#{$CN}__trans-stud-modal {
	&--invisible {
		visibility: hidden;
	}
}
